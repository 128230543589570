import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Grid, CircularProgress } from '@material-ui/core';
import { CheckCircleOutlineSharp, HighlightOffSharp } from '@material-ui/icons';
import { InstallPaper, InstallBox, InstallSwitch, Header, Label } from './parts';
import { RefreshButton } from './parts'
import { GridPowerFactor } from '.'
import { getGridSetting, sendGridSetting } from '../../../actions/installation/gridActions'

const StatusIcon = ({ status }) => {
    return status ? <CheckCircleOutlineSharp style={{ color: "green" }} /> : <HighlightOffSharp style={{ color: "red" }} />
}


function GridSwitch({ name, parameter, readonly }) {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.installation.grid.setting);
  const loading = useSelector(state => state.installation.grid.settingLoading);
  const setting = settings.find(x => x.parameter === parameter);

  useEffect(() => {
    dispatch(getGridSetting(parameter));
  }, [dispatch, parameter]);

  function handleSwitch(id, e) {
    dispatch(sendGridSetting(parameter, {value: e.target.checked}));
  }

  if (!setting) {
    return null;
  }

  return(
    <InstallBox>
      <Grid container justify="space-between" alignItems="center" spacing={0}>
        <Grid item xs={9}>
          <Label>{name}</Label>
        </Grid>
        <Grid item xs={3} align="right">
          {readonly ? 
            <StatusIcon status={setting.data.value} /> : 
            <InstallSwitch
              name={setting.data.parameter}
              size="small"
              checked={setting.data.value}
              onChange={(e) => handleSwitch(setting.data.parameter, e)}
              disabled={loading}
            />
          }
        </Grid>
      </Grid>
    </InstallBox>
  );
}


export function GridSetting({ readonly }) {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.installation.grid.setting);
  const loading = useSelector(state => state.installation.grid.settingLoading);

  const tmp = settings.find(x => x.parameter === 'voltvar');
  const voltvar = tmp?tmp.data.value:false;

  function handleRefresh() {
    dispatch(getGridSetting('drm0'));
    dispatch(getGridSetting('voltwatt'));
    dispatch(getGridSetting('voltvar'));
  }

  return (
    <InstallPaper>
      <Header>
        Settings
        {loading?<CircularProgress size={16}/>:<RefreshButton onClick={handleRefresh}/>}
      </Header>     
      <GridSwitch name='DRM0' parameter="drm0" readonly={readonly} />
      <GridSwitch name='Volt-Watt' parameter="voltwatt" readonly={readonly} />
      <GridSwitch name='Volt-Var' parameter="voltvar" readonly={readonly} />
      {voltvar?null:
        <GridPowerFactor readonly={readonly} />
      }

    </InstallPaper>
  );
};
