import API from '../../api';

// Create Redux action types
export const GET_DEBUG_STATE_REQUEST = 'GET_DEBUG_STATE_REQUEST';
export const GET_DEBUG_STATE_SUCCESS = 'GET_DEBUG_STATE_SUCCESS';
export const GET_DEBUG_STATE_FAILURE = 'GET_DEBUG_STATE_FAILURE';

// Create Redux action creators that return an action
export const getDebugState = () => ({
  type: GET_DEBUG_STATE_REQUEST,
})

export const getDebugStateSuccess = (data) => ({
  type: GET_DEBUG_STATE_SUCCESS,
  payload: data,
})

export const getDebugStateFailure = () => ({
  type: GET_DEBUG_STATE_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchDebugState() {
  return async (dispatch) => {
    dispatch(getDebugState())
    try {
      const response = await API.get('/api/debug/state/');
      dispatch(getDebugStateSuccess(response.data));
    } catch (error) {
      dispatch(getDebugStateFailure());
    }
  }
}

// Create Redux action types
export const PUT_DEBUG_STATE_CLEAR_REQUEST = 'PUT_DEBUG_STATE_CLEAR_REQUEST';
export const PUT_DEBUG_STATE_CLEAR_SUCCESS = 'PUT_DEBUG_STATE_CLEAR_SUCCESS';
export const PUT_DEBUG_STATE_CLEAR_FAILURE = 'PUT_DEBUG_STATE_CLEAR_FAILURE';

// Create Redux action creators that return an action
export const putDebugStateClear = () => ({
  type: PUT_DEBUG_STATE_CLEAR_REQUEST,
})

export const putDebugStateClearSuccess = (data) => ({
  type: PUT_DEBUG_STATE_CLEAR_SUCCESS,
  payload: data,
})

export const putDebugStateClearFailure = () => ({
  type: PUT_DEBUG_STATE_CLEAR_FAILURE,
})

// Combine them all in an asynchronous thunk
export function sendDebugStateClear() {
  return async (dispatch) => {
    dispatch(putDebugStateClear());
    try {
      const response = await API.get('/api/debug/state/clear/');
      dispatch(putDebugStateClearSuccess(response.data));
    } catch (error) {
      dispatch(putDebugStateClearFailure());
    }
  }
}
