import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Status from '../../components/Debug/Operation'
import StatusPicture from '../../components/Debug/OperationPicture'
import { fetchOperation,
         sendOperation,
         fetchOperationPicture,
         sendClearHalt } from '../../actions/operationActions'

const OperationBox = withStyles({
  root: {
    width: '100%',
    border: 'none',
    padding: '0px',
    marginTop: "20px"
  },
})(Box);

export function DebugOperationPage () {
  const dispatch = useDispatch()
  const operation = useSelector(state => state.operation.operation);
  const loading = useSelector(state => state.operation.loading);
  const picture = useSelector(state => state.operation.picture);
  const pictureError = useSelector(state => state.operation.pictureError);
  const loggedIn = useSelector(state => state.user.loggedIn);

	useEffect(() => {
	  dispatch(fetchOperation());
	  const intervalOp = setInterval(() => {
      dispatch(fetchOperation());  	
	  }, 5000);
	  return () => clearInterval(intervalOp);
	}, [dispatch]);

  useEffect(() => {
    dispatch(fetchOperationPicture());
    const intervalOpPicture = setInterval(() => {
      dispatch(fetchOperationPicture());
    }, 5000);
    return () => clearInterval(intervalOpPicture);
  }, [dispatch]);

  function updateOperation(data) {
    console.log(data);
    dispatch(sendOperation(data));
  }

  console.log(operation);

  return (
      <OperationBox id="operation-box">
        <Container maxWidth="lg">
          <Grid container item
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={1}
            xs={12}
            style={{ position: 'relative' }}
          >
            <Grid item xs={12} lg={3}>
              <Status
                loggedIn={loggedIn}
                loading={loading}
                operation={operation}
                update={updateOperation}
                clearHalt={() => dispatch(sendClearHalt())}
              />
            </Grid>
            <Grid item xs={12} lg={9}>
              <StatusPicture
                data={picture}
                error={pictureError}
              />
            </Grid>
          </Grid>
        </Container>
      </OperationBox>
  )
}
