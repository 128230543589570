import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


export const SignalLabel = withStyles({
  root: {
  	color: 'inherit',
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: '150%',
    textTransform: 'capitalize',
    letterSpacing: '0.8px',
    margin: "0px 0px 5px 0px",
  },
})(Typography);

export const StatusLabel = withStyles({
  root: {
  	display: 'inline-flex',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    letterSpacing: '0.8px',
    width: '100%',
    // height: '41px',
  },
})(Typography);

export const StatusValue = withStyles({
  root: {
   	display: 'inline-flex',
   	alignItems: 'center',
    fontSize: '13px',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    letterSpacing: '0.8px',
    width: '100%',
    // height: '41px',
	paddingLeft: '10px',
  },
})(Typography);
