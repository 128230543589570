import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Grid, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';

const Section = styled(Box)({
    minHeight: '50px',
    width: '100%',
    color: '#fff',
    background: '#42596b',
    border: 'none',
    padding: '0px',
    display: 'flex',
    alignItems: 'center'
  });

const Item = ({ label, value }) => {
    return <Grid style={{ display: 'flex', marginRight: '10px', fontSize: '11px' }}>
        <Grid item style={{ marginRight: '3px' }}>{label} - </Grid>
        <Grid item>{value}</Grid>
    </Grid>
}

const Footer = () => {
    const version = useSelector(state => state.user.version);

    return <Section id="footer">
        <Container maxWidth="lg">
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>© {new Date().getFullYear()} Empower Energy</Grid>
                <Grid item style={{
                    display: 'flex'
                }}>
                    {version && <>
                    <Item label="Model" value={`${version.model}`} />
                    <Item label="Software" value={`v${version.software}`} />
                    <Item label="Interface" value={`v${version.interface}`} />
                    </>}
                </Grid>
            </Grid>
        </Container>
    </Section>
}

export default Footer;
