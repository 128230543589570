import { styled } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';


export const ConfigPaper = styled(Paper)({
  width: '100%',
  backgroundColor: '#fafafa',
  fontSize: '24px',
  fontWeight: '200%',
  letterSpacing: '1.1px',
  textTransform: 'capitalize',
  color: '#004057',
  boxShadow: 'none',
  border: 'none',
  padding: '0px',
  marginTop: '10px',
});
