import API from '../api';

// Create Redux action types
export const GET_NETWORK_SETTING_REQUEST = 'GET_NETWORK_SETTING_REQUEST';
export const GET_NETWORK_SETTING_SUCCESS = 'GET_NETWORK_SETTING_SUCCESS';
export const GET_NETWORK_SETTING_FAILURE = 'GET_NETWORK_SETTING_FAILURE';

// Create Redux action creators that return an action
export const getNetworkSettingRequest = () => ({
  type: GET_NETWORK_SETTING_REQUEST,
})

export const getNetworkSettingSuccess = (data) => ({
  type: GET_NETWORK_SETTING_SUCCESS,
  payload: data,
})

export const getNetworkSettingFailure = (data) => ({
  type: GET_NETWORK_SETTING_FAILURE,
  payload: data,
})

// Combine them all in an asynchronous thunk
export function getNetworkSetting() {
  return async (dispatch) => {
    dispatch(getNetworkSettingRequest())
    try {
      const response = await API.get('/api/network/');
      dispatch(getNetworkSettingSuccess(response.data));
    } catch (error) {
      dispatch(getNetworkSettingFailure(error.response.data));
    }
  }
}


// Create Redux action types
export const PUT_NETWORK_SETTING_REQUEST = 'PUT_NETWORK_SETTING_REQUEST';
export const PUT_NETWORK_SETTING_SUCCESS = 'PUT_NETWORK_SETTING_SUCCESS';
export const PUT_NETWORK_SETTING_FAILURE = 'PUT_NETWORK_SETTING_FAILURE';
export const PUT_NETWORK_SETTING_CLEAR_ERROR = 'PUT_NETWORK_SETTING_CLEAR_ERROR';

// Create Redux action creators that return an action
export const putNetworkSettingRequest = () => ({
  type: PUT_NETWORK_SETTING_REQUEST,
})

export const putNetworkSettingSuccess = (data) => ({
  type: PUT_NETWORK_SETTING_SUCCESS,
  payload: data,
})

export const putNetworkSettingFailure = (data) => ({
  type: PUT_NETWORK_SETTING_FAILURE,
  payload: data,
})

export const clearNetworkSettingError = () => ({
  type: PUT_NETWORK_SETTING_CLEAR_ERROR,
  payload: null,
})

// Combine them all in an asynchronous thunk
export function sendNetworkSetting(data) {
  return async (dispatch) => {
    dispatch(putNetworkSettingRequest());
    try {
      const response = await API.post(`/api/network/`, data, {timeout: 30000});
      dispatch(putNetworkSettingSuccess(response.data));
      dispatch(getNetworkStatus());
    } catch (error) {
      dispatch(putNetworkSettingFailure(error.response.data));
    }
  }
}


// Create Redux action types
export const GET_NETWORK_SIGNAL_REQUEST = 'GET_NETWORK_SIGNAL_REQUEST';
export const GET_NETWORK_SIGNAL_SUCCESS = 'GET_NETWORK_SIGNAL_SUCCESS';
export const GET_NETWORK_SIGNAL_FAILURE = 'GET_NETWORK_SIGNAL_FAILURE';

// Create Redux action creators that return an action
export const getNetworkSignalRequest = () => ({
  type: GET_NETWORK_SIGNAL_REQUEST,
})

export const getNetworkSignalSuccess = (data) => ({
  type: GET_NETWORK_SIGNAL_SUCCESS,
  payload: data,
})

export const getNetworkSignalFailure = (data) => ({
  type: GET_NETWORK_SIGNAL_FAILURE,
  payload: data,
})

// Combine them all in an asynchronous thunk
export function getNetworkSignal() {
  return async (dispatch) => {
    dispatch(getNetworkSignalRequest())
    try {
      const response = await API.get('/api/network/signal/');
      dispatch(getNetworkSignalSuccess(response.data));
    } catch (error) {
      dispatch(getNetworkSignalFailure(error.response));
    }
  }
}


// Create Redux action types
export const GET_NETWORK_STATUS_REQUEST = 'GET_NETWORK_STATUS_REQUEST';
export const GET_NETWORK_STATUS_SUCCESS = 'GET_NETWORK_STATUS_SUCCESS';
export const GET_NETWORK_STATUS_FAILURE = 'GET_NETWORK_STATUS_FAILURE';

// Create Redux action creators that return an action
export const getNetworkStatusRequest = () => ({
  type: GET_NETWORK_STATUS_REQUEST,
})

export const getNetworkStatusSuccess = (data) => ({
  type: GET_NETWORK_STATUS_SUCCESS,
  payload: data,
})

export const getNetworkStatusFailure = (data) => ({
  type: GET_NETWORK_STATUS_FAILURE,
  payload: data,
})

// Combine them all in an asynchronous thunk
export function getNetworkStatus() {
  return async (dispatch) => {
    dispatch(getNetworkStatusRequest())
    try {
      const response = await API.get('/api/network/status/');
      dispatch(getNetworkStatusSuccess(response.data));
    } catch (error) {
      dispatch(getNetworkStatusFailure(error.response));
    }
  }
}


// Create Redux action types
export const GET_NETWORK_APLIST_REQUEST = 'GET_NETWORK_APLIST_REQUEST';
export const GET_NETWORK_APLIST_SUCCESS = 'GET_NETWORK_APLIST_SUCCESS';
export const GET_NETWORK_APLIST_FAILURE = 'GET_NETWORK_APLIST_FAILURE';

// Create Redux action creators that return an action
export const getNetworkApListRequest = () => ({
  type: GET_NETWORK_APLIST_REQUEST,
})

export const getNetworkApListSuccess = (data) => ({
  type: GET_NETWORK_APLIST_SUCCESS,
  payload: data,
})

export const getNetworkApListFailure = (data) => ({
  type: GET_NETWORK_APLIST_FAILURE,
  payload: data,
})

// Combine them all in an asynchronous thunk
export function getNetworkApList() {
  return async (dispatch) => {
    dispatch(getNetworkApListRequest())
    try {
      const response = await API.get('/api/network/aplist/');
      dispatch(getNetworkApListSuccess(response.data));
    } catch (error) {
      dispatch(getNetworkApListFailure(error.response.data));
    }
  }
}
