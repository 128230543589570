import API from '../api';

// Create Redux action types
export const GET_LOGS_REQUEST = 'GET_LOGS_REQUEST';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_LOGS_FAILURE = 'GET_LOGS_FAILURE';

// Create Redux action creators that return an action
export const getLogsRequest = () => ({
  type: GET_LOGS_REQUEST,
})

export const getLogsSuccess = (data) => ({
  type: GET_LOGS_SUCCESS,
  payload: data,
})

export const getLogsFailure = () => ({
  type: GET_LOGS_FAILURE,
})

// Combine them all in an asynchronous thunk
export function getLogs() {
  return async (dispatch) => {
    dispatch(getLogsRequest())
    try {
      const response = await API.get('/api/log');
      dispatch(getLogsSuccess(response.data))
    } catch (error) {
      dispatch(getLogsFailure())
    }
  }
}
