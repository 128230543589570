import { withStyles } from '@material-ui/core/styles';
import { Cached, Close, Check } from '@material-ui/icons';


export const FailedIcon = withStyles({
  root: {
    color: 'red',
    fontSize: '24px',
  },
})(Close);


export const RefreshIcon = withStyles({
  root: {
    color: 'inherit',
    fontSize: '24px',
  },
})(Cached);


export const SuccessIcon = withStyles({
  root: {
    color: 'green',
    fontSize: '24px',
  },
})(Check);
