import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';


export const Panel = withStyles({
  root: {
    backgroundColor: 'inherit',
    color: 'inherit',
    boxShadow: 'none',
    border: 'none',
    padding: '0px',
  },
})(Accordion);

export const PanelSummary = withStyles({
  root: {
    width: '100%',
    backgroundColor: 'inherit',
    fontSize: '18px',
    fontWeight: '150%',
    letterSpacing: '1.0px',
    color: 'inherit',
    padding: 'inherit',
  },
})(props => (<AccordionSummary expandIcon={<ArrowDropDown />} {...props}/>));

export const PanelDetails = withStyles({
  root: {
    backgroundColor: 'inherit',
    padding: 'inherit',
    fontSize: '13px',
    lineHeight: '14px',
  },
})(AccordionDetails);

export const StatusPanel = withStyles({
  root: {
    width: '100%',
    backgroundColor: 'inherit',
    color: 'inherit',
    boxShadow: 'none',
    border: 'none',
    paddingLeft: '5px',
  },
})(Accordion);

export const StatusPanelSummary = withStyles({
  root: {
    width: '100%',
    backgroundColor: 'inherit',
    fontSize: '14px',
    fontWeight: '100%',
    letterSpacing: '1.0px',
    color: 'inherit',
    padding: 'inherit',
  },
})(props => (<AccordionSummary expandIcon={<ArrowDropDown />} {...props}/>));

export const StatusPanelDetails = withStyles({
  root: {
    width: '100%',
    backgroundColor: 'inherit',
    padding: 'inherit',
    fontSize: '12px',
    lineHeight: '13px',
  },
})(AccordionDetails);
