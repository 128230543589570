// Import all actions
import * as actions from '../../actions/debug/statusActions'

export const initialState = {
  data: {},
  loading: false,
  error: false,
}

export function StatusReducer(state = initialState, action) {
  switch (action.type) {
    
    case actions.GET_DEBUG_STATUS_REQUEST:
      return { ...state, loading: true }
    case actions.GET_DEBUG_STATUS_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: false }
    case actions.GET_DEBUG_STATUS_FAILURE:
      return { ...state, loading: false, error: true }

    default:
      return state
  }
}
