// Import all actions
import * as actions from '../actions/upgradeActions'

export const initialState = {
  data: {
    check: null,
    message: null,
    running: null
  },
  loading: false,
  error: false,
}

export default function UpgradeReducer(state = initialState, action) {
  switch (action.type) {

    case actions.GET_UPGRADE_REQUEST:
    case actions.PUT_UPGRADE_REQUEST:
      return { ...state, loading: true }
    case actions.GET_UPGRADE_SUCCESS:
    case actions.PUT_UPGRADE_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: false }
    case actions.GET_UPGRADE_FAILURE:
    case actions.PUT_UPGRADE_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case actions.PUT_UPGRADE_CLEAR_ERROR:
      return { ...state, error: false }

    default:
      return state
  }
}
