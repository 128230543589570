import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';


export const ReportingPaper = withStyles({
  root: {
    width: '100%',
    backgroundColor: '#fafafa',
    fontSize: '24px',
    fontWeight: '200%',
    letterSpacing: '1.1px',
    textTransform: 'capitalize',
    color: '#004057',
    boxShadow: 'none',
    border: 'none',
    padding: '0px',
    marginTop: '10px',
  },
})(Paper);
