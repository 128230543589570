import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FailedIcon, RefreshIcon, SuccessIcon } from './Icon'

const IconButtonNoHover = withStyles({
  root: {
    color: 'inherit',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
})(IconButton);


export function FailedButton({ ...props }) {
  const {
    onClick,
    label,
    ...rest
  } = props;

  return (
    <Tooltip title={label?label:"Rerun"}>
      <IconButtonNoHover {...rest}
        size="small"
        onClick={e => onClick(e)}
      ><FailedIcon/>
      </IconButtonNoHover>
    </Tooltip>
  );
}


export function SuccessButton({ ...props }) {
  const {
    onClick,
    label,
    ...rest
  } = props;

  return (
    <Tooltip title={label?label:"Rerun"}>
      <IconButtonNoHover {...rest}
        size="small"
        onClick={e => onClick(e)}
      ><SuccessIcon/>
      </IconButtonNoHover>
    </Tooltip>
  );
}


export function RefreshButton({ ...props }) {
  const {
    onClick,
    label,
    ...rest
  } = props;

  return (
    <Tooltip title={label?label:"Refresh"}>
      <IconButtonNoHover {...rest}
        size="small"
        onClick={e => onClick(e)}
      ><RefreshIcon/>
      </IconButtonNoHover>
    </Tooltip>
  );
}
