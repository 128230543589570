import React from 'react';
import { Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


export const BlueRadio = withStyles({
  root: {
    color: '#004057',
    '&$checked': {
      color: '#004057',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

