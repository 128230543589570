import API from '../api';
import { setDoLogout } from './eventActions';

// Create Redux action types
export const GET_USER_LOGIN_REQUEST = 'GET_USER_LOGIN_REQUEST';
export const GET_USER_LOGIN_SUCCESS = 'GET_USER_LOGIN_SUCCESS';
export const GET_USER_LOGIN_FAILURE = 'GET_USER_LOGIN_FAILURE';

// Create Redux action creators that return an action
export const getUserLoginRequest = () => ({
  type: GET_USER_LOGIN_REQUEST,
})

export const getUserLoginSuccess = (data) => ({
  type: GET_USER_LOGIN_SUCCESS,
  payload: data,
})

export const getUserLoginFailure = (data) => ({
  type: GET_USER_LOGIN_FAILURE,
  payload: data
})

// Combine them all in an asynchronous thunk
export function getUserLogin() {
  return async (dispatch) => {
    dispatch(getUserLoginRequest());
    try {
      const response = await API.get('/api/login/');
      dispatch(getUserLoginSuccess(response.data));
    } catch (error) {
      dispatch(getUserLoginFailure(error.response.data));
    }
  }
}

// Create Redux action types
export const PUT_USER_LOGIN_REQUEST = 'PUT_USER_LOGIN_REQUEST';
export const PUT_USER_LOGIN_SUCCESS = 'PUT_USER_LOGIN_SUCCESS';
export const PUT_USER_LOGIN_FAILURE = 'PUT_USER_LOGIN_FAILURE';
export const PUT_USER_CLEAR_FAILURE = 'PUT_USER_CLEAR_FAILURE';

// Create Redux action creators that return an action
export const putUserLoginRequest = () => ({
  type: PUT_USER_LOGIN_REQUEST,
})

export const putUserLoginSuccess = (data) => ({
  type: PUT_USER_LOGIN_SUCCESS,
  payload: data,
})

export const putUserLoginFailure = (data) => ({
  type: PUT_USER_LOGIN_FAILURE,
  payload: data
})

export const userClearFailure = () => ({
  type:PUT_USER_CLEAR_FAILURE,
})

// Combine them all in an asynchronous thunk
export function sendUserLogin(data) {
  return async (dispatch) => {
    dispatch(putUserLoginRequest());
    try {
      const response = await API.post('/api/login/', data);
      dispatch(putUserLoginSuccess(response.data));
    } catch (error) {
      dispatch(putUserLoginFailure(error.response.data));
    }
  }
}

// Create Redux action types
export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

// Create Redux action creators that return an action
export const userLogoutRequest = () => ({
  type: USER_LOGOUT_REQUEST,
})

export const userLogoutSuccess = (data) => ({
  type: USER_LOGOUT_SUCCESS,
})

export const userLogoutFailure = () => ({
  type: USER_LOGOUT_FAILURE,
})

// Combine them all in an asynchronous thunk
export function sendUserLogout() {
  return async (dispatch) => {
    dispatch(userLogoutRequest())
    try {
      const response = await API.post('/api/logout/');
      dispatch(setDoLogout(false));
      dispatch(userLogoutSuccess(response.data))
    } catch (error) {
      dispatch(userLogoutFailure())
    }
  }
}

// Create Redux action types
export const USER_CHECK_LOGIN = 'USER_CHECK_LOGIN';
export const USER_REDIRECT_DONE = 'USER_REDIRECT_DONE';

// Create Redux action creators that return an action
export const userCheckLogin = (status) => ({
  type: USER_CHECK_LOGIN,
  payload: status,
})

export const userRedirectDone = () => ({
  type: USER_REDIRECT_DONE,
})
