import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Container, Grid } from '@material-ui/core';
import { InstallBox, Header, Label } from './components/parts'
import { FormBox, InstallPaper, SaveButton } from './components/parts'
import { makeStyles } from '@material-ui/core/styles';
import { getUpgrade, sendUpgrade } from '../../actions/upgradeActions';
import { format } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import { isEmpty, isNil } from 'lodash';

const useStyles = makeStyles({
    installBox: {
        marginBottom: '8px'
    },
    alert: {
        padding: '0 8px',
        marginBottom: '8px'
    }
});

export default function UpgradePage () {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [connected, setConnected] = useState(true);
    const loading = useSelector(state => state.upgrade.loading);
    const data = useSelector(state => state.upgrade.data);
    const status = useSelector(state => state.event.status);

    useEffect(() => {
        dispatch(getUpgrade());
    }, [dispatch]);

    useEffect(() => {
        if (!loading && connected) {
            const timeoutEvent = setTimeout(() => {
            dispatch(getUpgrade());
            }, 60000);
            return () => clearTimeout(timeoutEvent);
        }
    }, [loading, connected, dispatch]);

    useEffect(() => {
        if (!isNil(status?.connection?.server) || !isNil(status?.connection?.internet)) {
            if (!status?.connection?.server || !status?.connection?.internet) {            
                setConnected(false);
            } else {
                setConnected(true);
            }   
        }        
    }, [status]);

    
    const checkNow = () => {
        dispatch(sendUpgrade({
            "command": "start"
        }));
    }

    return <Container maxWidth="lg">
    <Grid container justify="space-between" spacing={1}>
      <Grid item xs={5}>
        <InstallPaper>
            <Header>
                Upgrade
                {loading ? <CircularProgress size={16}/> : null}
            </Header>
            <FormBox>
                {!connected && <Alert className={classes.alert} severity='error'>
                    {!status?.connection?.server ? 'Server Not Available' : !status?.connection?.internet ? 'Internet Not Connected' : ''}
                </Alert>}
                {!isEmpty(data?.message) && <Alert className={classes.alert} severity={data?.running ? 'info' : 'warning'}>{data?.message}</Alert>}
                <Grid container spacing={0} justify="center" alignItems="center">
                    <InstallBox className={classes.installBox}>
                        {data?.running?
                            <Grid container justify="space-between" alignItems="center" spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <Label>Upgrade Started</Label>
                                </Grid>
                                <Grid item xs={11} sm={4}>
                                    <Label>{`${format(new Date(data.last_check * 1000), 'yyyy-MM-dd HH:mm:ss')}`}</Label>
                                </Grid>
                            </Grid>
                            :<Grid container justify="space-between" alignItems="center" spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <Label>Last Updated</Label>
                                </Grid>
                                <Grid item xs={11} sm={4}>
                                    <Label>{`${data?.last_check ? format(new Date(data.last_check * 1000), 'yyyy-MM-dd HH:mm:ss') :  'NA'}`}</Label>
                                </Grid>
                            </Grid>
                        }
                    </InstallBox>
                    <SaveButton
                        onClick={checkNow}
                        fullWidth
                        label="Check Now"
                        disabled={loading || !connected}
                    /> 
                </Grid>
            </FormBox>
        </InstallPaper>
      </Grid>
    </Grid>
  </Container>
}

