// Import all actions
import * as actions from '../actions/operationActions'

import { operationPicture } from '../utils/enums'


export const initialState = {
  interactive: undefined,
  operation: undefined,
  picture: operationPicture,
  loading: false,
  error: false,
  pictureLoading: false,
  pictureError: false,
}

export default function operationPictureReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_INTERACTIVE_REQUEST:
      return { ...state, loading: true }
    case actions.GET_INTERACTIVE_SUCCESS:
      return { ...state, interactive: action.payload, loading: false, error: false }
    case actions.GET_INTERACTIVE_FAILURE:
      return { ...state, loading: false, error: true }
      case actions.PUT_INTERACTIVE_REQUEST:
        return { ...state, loading: true }
      case actions.PUT_INTERACTIVE_SUCCESS:
        return { ...state, interactive: action.payload, loading: false, error: false }
      case actions.PUT_INTERACTIVE_FAILURE:
        return { ...state, loading: false, error: true }
    case actions.GET_OPERATION_REQUEST:
      return { ...state, loading: true }
    case actions.GET_OPERATION_SUCCESS:
      return { ...state, operation: action.payload, loading: false, error: false }
    case actions.GET_OPERATION_FAILURE:
      return { ...state, loading: false, error: true }
    case actions.PUT_OPERATION_REQUEST:
      return { ...state, loading: true }
    case actions.PUT_OPERATION_SUCCESS:
      return { ...state, operation: action.payload, loading: false, error: false }
    case actions.PUT_OPERATION_FAILURE:
      return { ...state, loading: false, error: true }
    case actions.GET_OPERATION_PICTURE_REQUEST:
      return { ...state, pictureLoading: true }
    case actions.GET_OPERATION_PICTURE_SUCCESS:
      return { ...state, picture: action.payload, pictureLoading: false, pictureError: false }
    case actions.GET_OPERATION_PICTURE_FAILURE:
      return { ...state, picture: operationPicture, pictureLoading: false, pictureError: true }
    case actions.PUT_CLEAR_HALT_REQUEST:
      return { ...state, loading: true }
    case actions.PUT_CLEAR_HALT_SUCCESS:
      return { ...state, loading: false, error: false }
    case actions.PUT_CLEAR_HALT_FAILURE:
      return { ...state, loading: false, error: true }
    default:
      return state
  }
}


