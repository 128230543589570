import axios from 'axios';


export default axios.create({
  baseURL: window.location.origin.toString(),
  // baseURL: "http://192.168.42.161",
  // baseURL: "http://unit.elektrobank.com.au",
  timeout: 15000,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken'
});
