// Import all actions
import * as actions from '../actions/userActions'


export const initialState = {
  links: undefined,
  name: undefined,
  serial: undefined,
  loggedIn: false,
  redirect: false,
  loading: false,
  error: false,
  version: undefined
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {

    case actions.GET_USER_LOGIN_REQUEST:
    case actions.PUT_USER_LOGIN_REQUEST:
      return { ...state, loading: true }
    case actions.GET_USER_LOGIN_SUCCESS:
    case actions.PUT_USER_LOGIN_SUCCESS:
      return { ...state,
               links: action.payload.links,
               name: action.payload.name,
               serial: action.payload.serial,
               loggedIn: action.payload.active,
               version: action.payload.version,
               loading: false,
               error: false
             }
    case actions.GET_USER_LOGIN_FAILURE:
    case actions.PUT_USER_LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case actions.PUT_USER_CLEAR_FAILURE:
      return { ...state, error: false }

    case actions.USER_LOGOUT_REQUEST:
      return { ...state, loading: true }
    case actions.USER_LOGOUT_SUCCESS:
      return { ...state,
               links: undefined,
               name: undefined,
               serial: undefined,
               loggedIn: false,
               loading: false,
               error: false
             }
    case actions.USER_LOGOUT_FAILURE:
      return { ...state, loading: false, error: true }

    case actions.USER_CHECK_LOGIN:
      if ((state.loggedIn) && (state.loggedIn !== action.payload)) {
        console.log("Session Expired");
        return { ...state,
                 links: undefined,
                 name: undefined,
                 serial: undefined,
                 loggedIn: false,
                 redirect: true
               }
      }
      return state
    case actions.USER_REDIRECT_DONE:
      return { ...state, redirect: false }
    default:
      return state
  }
}


