import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Grid, CircularProgress } from '@material-ui/core';
import { ConfigPaper, RefreshButton } from './parts';
import { StatusBox, ItemBox, Header, Title, Label, Value } from './parts'


function StatusItem({ id, name, value }) {
  return (
    <ItemBox key={id}>
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item xs={6}>
          <Label>{name}</Label>
        </Grid>
        <Grid item  xs={6}>
          <Value align="right">{value}</Value>
        </Grid>
      </Grid>
    </ItemBox>
  );
}

function InterfaceStatus({ title, data }) {
  if (!data) {
    return null;
  }

  if (!data.ip) {
    return (
      <StatusBox>
        <Title>{title} Not Connected</Title>
      </StatusBox>
    );
  }

  return (
    <StatusBox>
      <Title>{title}</Title>
      <StatusItem
        id={data.type + data.iface + 'ip'}
        name="IP Address"
        value={data.ip}
      />
      <StatusItem
        id={data.type + data.iface + 'netmask'}
        name="Netmask"
        value={data.netmask}
      />
      <StatusItem
        id={data.type + data.iface + 'gateway'}
        name="Default Gateway"
        value={data.gateway}
      />
      <StatusItem
        id={data.type + data.iface + 'dns'}
        name="DNS"
        value={data.dns.join(', ')}
      />
      <StatusItem
        id={data.type + data.iface + 'signal'}
        name="Signal Quality"
        value={data.signal}
      />
    </StatusBox>
  );
}

export function NetworkStatus ({ title, refresh }) {
  const dispatch = useDispatch();
  const status = useSelector(state => state.network.status);
  const loading = useSelector(state => state.network.loading);

  const data = Object.values(status).filter(item => item.primary)[0];

  return (
    <ConfigPaper>
      <Header>
        Status
        {loading?<CircularProgress size={16}/>:<RefreshButton onClick={refresh}/>}
      </Header>
      <InterfaceStatus title={title} data={data} />
    </ConfigPaper>
  );
};
