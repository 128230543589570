import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container';
import { fetchDebugStatus } from '../../actions/debug/statusActions'
import { sendClearHalt } from '../../actions/operationActions'
import StatusDisplay from '../../components/Debug/Status';

// ========================================

export function DebugStatusPage () {
  const dispatch = useDispatch()
  const data = useSelector(state => state.debug.status.data)
  const loading = useSelector(state => state.debug.status.loading)
  const error = useSelector(state => state.debug.status.error)
  const clearing = useSelector(state => state.operation.clearing)

  useEffect(() => {
    dispatch(fetchDebugStatus());
    const intervalSystem = setInterval(() => {
      dispatch(fetchDebugStatus());
    }, 2000);
    return () => clearInterval(intervalSystem);
  }, [dispatch]);

  function clearHalt() {
    dispatch(sendClearHalt());
  }

  function refreshData() {
    dispatch(fetchDebugStatus());
  }

  return (
    <Container maxWidth="xl">
      <StatusDisplay
        loading={loading}
        data={data}
        refresh={refreshData}
        clearHalt={clearHalt}
        clearing={clearing}
        error={error}
      /> 
    </Container>
  );
}
