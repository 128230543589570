import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


export const SaveButton = withStyles({
  root: {
    marginLeft: '5px',
    // width: '25%',
    // maxWidth: '100px',
    height: '30px',
    fontSize: '13px',
    padding: '0px',
    border: "1px solid #004057",
    '&:hover': {
      color: '#fff',
      background: '#004057',
    },
    '&:disabled': {
    },
  },
})(props => 
  <Button
    disableRipple
    disableElevation
    variant="outlined"
    {...props}
  >{props.label}</Button>
);

export const SelectButton = withStyles({
  root: {
    marginTop: '3px',
    maxWidth: 'inherit',
    height: '24px',
    fontSize: '12px',
    padding: '0px',
    border: "1px solid #004057",
    '&:hover': {
      color: '#fff',
      background: '#004057',
    },
    '&:disabled': {
    },
  },
})(Button);


export const UpdateButton = withStyles({
  root: {
    marginTop: '3px',
    maxWidth: '80px',
    // height: '20px',
    fontSize: '11px',
    padding: '0px',
    '&:hover': {
      color: '#fff',
      background: '#004057',
    },
    '&:disabled': {
    },
  },
})(Button);
