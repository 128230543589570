import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Grid, CircularProgress, Slider } from '@material-ui/core';
import { InstallPaper, InstallBox, Header, Label, RefreshButton } from './parts';
import { getGridSetting, sendGridSetting } from '../../../actions/installation/gridActions'


export function GridWgra({ readonly }) {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.installation.grid.setting);
  const loading = useSelector(state => state.installation.grid.settingLoading);
  const [sliderValue, setSliderValue] = useState(0);
  const setting = settings.find(x => x.parameter === 'wgra');
  const sliderMarks = [{value: 5, label: '5%'}, {value: 50, label: '50%'}, {value: 100, label: '100%'}];   

  useEffect(() => {
    dispatch(getGridSetting('wgra'));
  }, [dispatch]);

  useEffect(() => {
    if (setting) {
      setSliderValue(setting.data.value);
    }
  }, [settings, setting]);

  function handleRefresh() {
    dispatch(getGridSetting('wgra'));
  }

  function handleSlider(value) {
    dispatch(sendGridSetting(setting.parameter, {value: value}));
  }

  return (
    <InstallPaper>
      <Header>
        Power Rate Limit
        {loading?<CircularProgress size={16}/>:<RefreshButton onClick={handleRefresh}/>}
      </Header>
      <InstallBox>
        <Grid container justify="space-between" alignItems="center" spacing={0}>
          <Grid item xs={6}>
            <Label>WGRA</Label>
          </Grid>
          <Grid item xs={4}>
            {readonly?null:
              <Slider
              value={sliderValue}
              step={0.5}
              min={5}
              max={100}
              marks={sliderMarks}
              valueLabelDisplay="auto"
              onChange={(e, val) => setSliderValue(val)}
              onChangeCommitted={(e, val) => handleSlider(val)}
              disabled={loading}
            />}
          </Grid>
          <Grid item align="right" xs={2}>
            {loading?<CircularProgress size={16}/>
              :<Label>{setting?setting.data.value:null} % / minute</Label>}
          </Grid>
        </Grid>
      </InstallBox>
    </InstallPaper>
  );
};
