import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { sendUserLogout } from '../actions/userActions'
import storage from 'redux-persist/lib/storage';

export function Logout() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.user.loggedIn);

  useEffect(() => {
    storage.removeItem('persist:user');
    dispatch(sendUserLogout());
  }, [dispatch]);

  if (!loggedIn) {
    return (<Redirect to="/operation" />);
  }

  return (
    <div className="Logout">
    </div>
  );
}
