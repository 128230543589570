import API from '../../api';

// Create Redux action types
export const GET_GRID_REGION_REQUEST = 'GET_GRID_REGION_REQUEST';
export const GET_GRID_REGION_SUCCESS = 'GET_GRID_REGION_SUCCESS';
export const GET_GRID_REGION_FAILURE = 'GET_GRID_REGION_FAILURE';

// Create Redux action creators that return an action
export const getGridRegionRequest = () => ({
  type: GET_GRID_REGION_REQUEST,
})

export const getGridRegionSuccess = (data) => ({
  type: GET_GRID_REGION_SUCCESS,
  payload: data,
})

export const getGridRegionFailure = (data) => ({
  type: GET_GRID_REGION_FAILURE,
  payload: data,
})

// Combine them all in an asynchronous thunk
export function getGridRegion() {
  return async (dispatch) => {
    dispatch(getGridRegionRequest())
    try {
      const response = await API.get('/api/installation/region/');
      dispatch(getGridRegionSuccess(response.data));
    } catch (error) {
      dispatch(getGridRegionFailure(error.response.data));
    }
  }
}

// Create Redux action types
export const PUT_GRID_REGION_REQUEST = 'PUT_GRID_REGION_REQUEST';
export const PUT_GRID_REGION_SUCCESS = 'PUT_GRID_REGION_SUCCESS';
export const PUT_GRID_REGION_FAILURE = 'PUT_GRID_REGION_FAILURE';
export const PUT_GRID_REGION_CLEAR_ERROR = 'PUT_GRID_REGION_CLEAR_ERROR';

// Create Redux action creators that return an action
export const putGridRegionRequest = () => ({
  type: PUT_GRID_REGION_REQUEST,
})

export const putGridRegionSuccess = (data) => ({
  type: PUT_GRID_REGION_SUCCESS,
  payload: data,
})

export const putGridRegionFailure = (data) => ({
  type: PUT_GRID_REGION_FAILURE,
  payload: {data},
})

export const clearGridRegionError = () => ({
  type: PUT_GRID_REGION_CLEAR_ERROR,
  payload: null,
})

// Combine them all in an asynchronous thunk
export function sendGridRegion(data) {
  return async (dispatch) => {
    dispatch(putGridRegionRequest());
    try {
      const response = await API.put(`/api/installation/region/`, {region: data});
      dispatch(putGridRegionSuccess(response.data));
    } catch (error) {
      dispatch(putGridRegionFailure(error.response.data));
    }
  }
}

// Create Redux action types
export const GET_GRID_REGION_OPTION_REQUEST = 'GET_GRID_REGION_OPTION_REQUEST';
export const GET_GRID_REGION_OPTION_SUCCESS = 'GET_GRID_REGION_OPTION_SUCCESS';
export const GET_GRID_REGION_OPTION_FAILURE = 'GET_GRID_REGION_OPTION_FAILURE';

// Create Redux action creators that return an action
export const getGridRegionOptionRequest = () => ({
  type: GET_GRID_REGION_OPTION_REQUEST,
})

export const getGridRegionOptionSuccess = (data) => ({
  type: GET_GRID_REGION_OPTION_SUCCESS,
  payload: data,
})

export const getGridRegionOptionFailure = (data) => ({
  type: GET_GRID_REGION_OPTION_FAILURE,
  payload: data,
})

// Combine them all in an asynchronous thunk
export function getGridRegionOption() {
  return async (dispatch) => {
    dispatch(getGridRegionOptionRequest())
    try {
      const response = await API.get('/api/installation/region/option/');
      dispatch(getGridRegionOptionSuccess(response.data));
    } catch (error) {
      dispatch(getGridRegionOptionFailure(error.response.data));
    }
  }
}

// Create Redux action types
export const GET_GRID_SETTING_REQUEST = 'GET_GRID_SETTING_REQUEST';
export const GET_GRID_SETTING_SUCCESS = 'GET_GRID_SETTING_SUCCESS';
export const GET_GRID_SETTING_FAILURE = 'GET_GRID_SETTING_FAILURE';

// Create Redux action creators that return an action
export const getGridSettingRequest = () => ({
  type: GET_GRID_SETTING_REQUEST,
})

export const getGridSettingSuccess = (parameter, data) => ({
  type: GET_GRID_SETTING_SUCCESS,
  payload: {parameter, data},
})

export const getGridSettingFailure = (parameter, data) => ({
  type: GET_GRID_SETTING_FAILURE,
  payload: {parameter, data},
})

// Combine them all in an asynchronous thunk
export function getGridSetting(parameter) {
  return async (dispatch) => {
    dispatch(getGridSettingRequest())
    try {
      const response = await API.get(`/api/installation/setting/${parameter}/`);
      dispatch(getGridSettingSuccess(parameter, response.data));
    } catch (error) {
      dispatch(getGridSettingFailure(parameter, error?.response?.data));
    }
  }
}

// Create Redux action types
export const PUT_GRID_SETTING_REQUEST = 'PUT_GRID_SETTING_REQUEST';
export const PUT_GRID_SETTING_SUCCESS = 'PUT_GRID_SETTING_SUCCESS';
export const PUT_GRID_SETTING_FAILURE = 'PUT_GRID_SETTING_FAILURE';
export const PUT_GRID_SETTING_CLEAR_ERROR = 'PUT_GRID_SETTING_CLEAR_ERROR';

// Create Redux action creators that return an action
export const putGridSettingRequest = () => ({
  type: PUT_GRID_SETTING_REQUEST,
})

export const putGridSettingSuccess = (parameter, data) => ({
  type: PUT_GRID_SETTING_SUCCESS,
  payload: {parameter, data},
})

export const putGridSettingFailure = (parameter, data) => ({
  type: PUT_GRID_SETTING_FAILURE,
  payload: {parameter, data},
})

export const clearGridSettingError = () => ({
  type: PUT_GRID_SETTING_CLEAR_ERROR,
  payload: null,
})

// Combine them all in an asynchronous thunk
export function sendGridSetting(parameter, data) {
  return async (dispatch) => {
    dispatch(putGridSettingRequest());
    try {
      const response = await API.put(`/api/installation/setting/${parameter}/`, data);
      dispatch(putGridSettingSuccess(parameter, response.data));
    } catch (error) {
      dispatch(putGridSettingFailure(parameter, error.response));
    }
  }
}
