import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Grid, CircularProgress, Slider } from '@material-ui/core';
import { InstallPaper, InstallBox, Header, Label } from './parts';
import { RefreshButton, BlueRadio } from './parts'
import { getGridSetting, sendGridSetting, putGridSettingSuccess } from '../../../actions/installation/gridActions'

export function GridPowerFactor({ readonly }) {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.installation.grid.setting);
  const loading = useSelector(state => state.installation.grid.settingLoading);
  const [absorbing, setAbsorbing] = useState(true);
  const [pfMode, setPfMode] = useState(true);
  const [slider, setSlider] = useState({value: 1, max: 1, min: 0.6, step: 0.05});
  const pf = settings.find(x => x.parameter === 'power_factor');
  const reactive = settings.find(x => x.parameter === 'reactive_power');

  useEffect(() => {
    dispatch(getGridSetting('power_factor'));
    dispatch(getGridSetting('reactive_power'));
  }, [dispatch]);

  useEffect(() => {
    if (pf && reactive) {
      if (reactive) {
        if (reactive.data.value !== null) {
          setPfMode(false);
          setAbsorbing(parseFloat(reactive.data.value)<0?true:false);
          setSlider({value: Math.abs(reactive.data.value)*100, max: 60, min: 0, step: 5});
        } else {
          if (pf) {
            setPfMode(true);
            setAbsorbing(parseFloat(pf.data.value)<0?true:false);
            setSlider({value: Math.abs(pf.data.value), max: 1, min: 0.6, step: 0.05});
          }
        }
      }
    }
  }, [settings, pf, reactive]);


  function handleRefresh() {
    dispatch(getGridSetting('power_factor'));
    dispatch(getGridSetting('reactive_power'));
  }

  function handleModeChange(mode) {
    setPfMode(mode);
    if (mode) {
      dispatch(sendGridSetting(reactive.parameter, {value: null}));
      dispatch(putGridSettingSuccess(pf.parameter, {value: 1}));
      setSlider({value: Math.abs(pf.data.value), max: 1, min: 0.6, step: 0.05});
    } else {
      dispatch(sendGridSetting(reactive.parameter, {value: 0}));
      setSlider({value: Math.abs(reactive.data.value)*100, max: 60, min: 0, step: 5});
    }
  }

  function handleSliderChange(value) {
    if (pfMode) {
      dispatch(sendGridSetting(pf.parameter, {value: (absorbing?-1:1)*value}));
    } else {
      dispatch(sendGridSetting(reactive.parameter, {value: (absorbing?-1:1)*value/100}));
    }
  }

  function handleAbsorbChange(value) {
    setAbsorbing(value);
    if (pfMode) {
      dispatch(sendGridSetting(pf.parameter, {value: (value?-1:1)*slider.value}));
    } else {
      dispatch(sendGridSetting(reactive.parameter, {value: (value?-1:1)*slider.value/100}));
    }
  }

  function valueLabel() {
    if (pfMode) {
      return pf?Math.abs(pf.data.value):'1';
    } else {
      return reactive?`${Math.abs(reactive.data.value)*100}%`:'0%';
    }
  }

  function renderSlider() {
    const pfMarks = [{value: 0.6, label: '0.6'}, {value: 0.8, label: '0.8'}, {value: 1, label: '1'}];
    const varMarks = [{value: 0, label: '0%'}, {value: 30, label: '30%'}, {value: 60, label: '60%'}];

    return(
      <Grid container justify="space-between" alignItems="center" spacing={0}>
        <Grid item xs={4}>
          <Grid container spacing={0} justify="flex-start" alignItems="center">
              <Grid item xs={12}>
                <Grid container spacing={1} justify="flex-start" alignItems="center">
                  <BlueRadio 
                    size="small"
                    checked={absorbing}
                    onChange={() => handleAbsorbChange(true)}
                  />
                  <Label>Absorbing</Label>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} justify="flex-start" alignItems="center">
                  <BlueRadio
                    size="small"
                    checked={!absorbing}
                    onChange={() => handleAbsorbChange(false)}
                  />
                  <Label>Supplying</Label>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Slider
            value={slider.value}
            step={slider.step}
            min={slider.min}
            max={slider.max}
            marks={pfMode?pfMarks:varMarks}
            valueLabelDisplay="auto"
            onChange={(e, val) => setSlider({ ...slider, value: val})}
            onChangeCommitted={(e, val) => handleSliderChange(val)}
            disabled={loading || readonly}
          />
        </Grid>
      </Grid>
    );
  }

  return(
    <InstallPaper>
      <Header>
        Power Factor
        {loading?<CircularProgress size={16}/>:<RefreshButton onClick={handleRefresh}/>}
      </Header>  
      <InstallBox>
        <Grid container justify="space-between" alignItems="center" spacing={0}>
          <Grid item xs={4}>
            <Grid container spacing={1} justify="flex-start" alignItems="center">
              <BlueRadio
                size="small"
                checked={pfMode}
                onChange={() => handleModeChange(true)}
              />
              <Label>Power Factor</Label>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {pfMode?renderSlider():null}
          </Grid>
          <Grid item xs={2} align="right">
            {pfMode?
              loading?<CircularProgress size={16}/>:<Label>{valueLabel()}</Label>
              :null
            }
          </Grid>
        </Grid>
      </InstallBox>
      <InstallBox>
        <Grid container justify="space-between" alignItems="center" spacing={0}>
          <Grid item xs={4}>
            <Grid container spacing={1} justify="flex-start" alignItems="center">
              <BlueRadio 
                size="small"
                checked={!pfMode}
                onChange={() => handleModeChange(false)}
              />
              <Label>Reactive Power</Label>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {pfMode?null:renderSlider()}
          </Grid>
          <Grid item xs={2} align="right">
            {pfMode?
              null:
              loading?<CircularProgress size={16}/>:<Label>{valueLabel()}</Label>
            }
          </Grid>
        </Grid>
      </InstallBox>
    </InstallPaper>
  );
}
