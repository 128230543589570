import { withStyles } from '@material-ui/core/styles';
import { Cached, Check, Close, Refresh, Schedule } from '@material-ui/icons';


export const FailedIcon = withStyles({
  root: {
    color: 'red',
    fontSize: '24px',
  },
})(Close);


export const SuccessIcon = withStyles({
  root: {
    color: 'green',
    fontSize: '24px',
  },
})(Check);


export const RetryIcon = withStyles({
  root: {
    color: 'inherit',
    fontSize: '24px',
  },
})(Refresh);


export const WaitingIcon = withStyles({
  root: {
    color: 'inherit',
    fontSize: '24px',
  },
})(Schedule);


export const RefreshIcon = withStyles({
  root: {
    color: 'inherit',
    fontSize: '24px',
  },
})(Cached);
