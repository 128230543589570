export const operationOptions = {
  MODE_OPTIONS: [
    { value: 'auto', label: 'Automatic' },
    { value: 'manual', label: 'Manual' },
  ],
  SYSTEM_OPTIONS: [
    { value: 'interactive', label: 'Grid Interactive' },
    { value: 'debug', label: 'Debug' },
    { value: 'off', label: 'Off' }
  ],
  BATTERY_OPTIONS: [
    { value: 'off', label: 'Off' },
    { value: 'charge', label: 'Charge' },
    { value: 'discharge', label: 'Discharge' },
    { value: 'min_export', label: 'Minimise Export' },
    { value: 'min_import', label: 'Minimise Import' },
    { value: 'min_usage', label: 'Minimise Usage' }
  ],
  BATTERY_LIMIT_OPTIONS: [
    { value: '1000', label: '1kW' },
    { value: '2000', label: '2kW' },
    { value: '3000', label: '3kW' },
    { value: '4000', label: '4kW' },
    { value: '5000', label: '5kW' }
  ],
  BATTERY_RESERVE_OPTIONS: [
    { value: '0', label: 'Disabled' },
    { value: '5', label: '5%' },
    { value: '25', label: '25%' },
    { value: '50', label: '50%' },
    { value: '100', label: '100%' }
  ],
  COMPARATOR_OPTIONS: [
    { value: '>', label: '>' },
    { value: '=', label: '=' },
    { value: '<', label: '<' }
  ],
  DURATION_OPTIONS: [
    { value: '300', label: '5 minutes' },
    { value: '900', label: '15 minutes' },
    { value: '1800', label: '30 minutes' },
    { value: '3600', label: '1 hour' },
    { value: '7200', label: '2 hours' },
    { value: '14400', label: '4 hours' },
    { value: '28800', label: '8 hours' },
    { value: '43200', label: '12 hours' },
    { value: '86400', label: '24 hours' }
  ],
  PRICING_OPTIONS: [
    { value: 'house', label: 'House' },
    { value: 'load1', label: 'Controlled Load 1' },
    { value: 'load2', label: 'Controlled Load 2' }
  ]
}

export const operationPicture = {
  solar: { status: '', load: 0 },
  battery: { status: '', load: 0, mode: '', reserved: 0, level: 0 },
  house: { status: '', load: 0 },
  grid: { status: '', load: 0, buy: '', sell: '' },
  arrows: [
    { id: 1, mode: '' },
    { id: 2, mode: '' },
    { id: 3, mode: '' },
    { id: 4, mode: '' }
  ]
}