import API from '../api';

export const GET_UPGRADE_REQUEST = 'GET_UPGRADE_REQUEST';
export const GET_UPGRADE_SUCCESS = 'GET_UPGRADE_SUCCESS';
export const GET_UPGRADE_FAILURE = 'GET_UPGRADE_FAILURE';

export const getUpgradeRequest = () => ({
  type: GET_UPGRADE_REQUEST,
})

export const getUpgradeSuccess = (data) => ({
  type: GET_UPGRADE_SUCCESS,
  payload: data,
})

export const getUpgradeFailure = (data) => ({
  type: GET_UPGRADE_FAILURE,
  payload: data,
})

export function getUpgrade() {
  return async (dispatch) => {
    dispatch(getUpgradeRequest())
    try {
      const response = await API.get('/api/upgrade/');
      dispatch(getUpgradeSuccess(response?.data));
    } catch (error) {
      dispatch(getUpgradeFailure(error.response.data));
    }
  }
}

export const PUT_UPGRADE_REQUEST = 'PUT_UPGRADE_REQUEST';
export const PUT_UPGRADE_SUCCESS = 'PUT_UPGRADE_SUCCESS';
export const PUT_UPGRADE_FAILURE = 'PUT_UPGRADE_FAILURE';
export const PUT_UPGRADE_CLEAR_ERROR = 'PUT_UPGRADE_CLEAR_ERROR';

export const putUpgradeRequest = () => ({
  type: PUT_UPGRADE_REQUEST,
})

export const putUpgradeSuccess = (data) => ({
  type: PUT_UPGRADE_SUCCESS,
  payload: data,
})

export const putUpgradeFailure = (data) => ({
  type: PUT_UPGRADE_FAILURE,
  payload: data,
})

export const clearUpgradeError = () => ({
  type: PUT_UPGRADE_CLEAR_ERROR,
  payload: null,
})

export function sendUpgrade(data) {
  return async (dispatch) => {
    dispatch(putUpgradeRequest());
    try {
      const response = await API.put(`/api/upgrade/`, data);
      dispatch(putUpgradeSuccess(response.data));
    } catch (error) {
      dispatch(putUpgradeFailure(error.response.data));
    }
  }
}
