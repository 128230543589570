import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Status from '../components/Operation/Status'
import StatusPicture from '../components/Operation/StatusPicture'
import { fetchInteractive,
         sendInteractive,
         fetchOperationPicture,
         sendClearHalt } from '../actions/operationActions'

const OperationBox = withStyles({
  root: {
    width: '100%',
    color: '#fff',
    background: '#003f56',
    border: 'none',
    padding: '0px',
    marginTop: "4px"
  },
})(Box);

const UsageBox = withStyles({
  root: {
    width: '100%',
    minHeight: '300px',
    color: '#fff',
    background: '#00394e',
    border: 'none',
    padding: '0px',
    marginTop: "0px",
  },
})(Box);


export default function OperationPage () {
  const dispatch = useDispatch()
  const interactive = useSelector(state => state.operation.interactive);
  const picture = useSelector(state => state.operation.picture);
  const loading = useSelector(state => state.operation.loading);
  const pictureError = useSelector(state => state.operation.pictureError);
  const loggedIn = useSelector(state => state.user.loggedIn);

	useEffect(() => {
	  dispatch(fetchInteractive());
	  const intervalOp = setInterval(() => {
      dispatch(fetchInteractive());  	
	  }, 5000);
	  return () => clearInterval(intervalOp);
	}, [dispatch]);

  useEffect(() => {
    dispatch(fetchOperationPicture());
    const intervalOpPicture = setInterval(() => {
      dispatch(fetchOperationPicture());
    }, 5000);
    return () => clearInterval(intervalOpPicture);
  }, [dispatch]);

  function updateOperation(data) {
    dispatch(sendInteractive(data));
  }

  const getHeight = () => {
    const topbar = document.getElementById("topbar");
    const footer = document.getElementById("footer");
    const operationBox = document.getElementById("operation-box");
    let height = 0;
    if (topbar) {
      height = height + topbar.clientHeight;
    }
    if (footer) {
      height = height + footer.clientHeight;
    }
    if (operationBox) {
      height = height + operationBox.clientHeight - 4;
    }

    return `calc(100vh - ${height}px)`;
  }

  return (
    <div style={{ height: '100%' }}>
      <OperationBox id="operation-box">
        <Container maxWidth="lg">
          <Grid container item
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={1}
            xs={12}
            style={{ position: 'relative' }}
          >
            <Grid item xs={12} lg={3}>
              <Status
                loggedIn={loggedIn}
                data={interactive}
                loading={loading}
                update={updateOperation}
                clearHalt={() => dispatch(sendClearHalt())}
              />
            </Grid>
            <Grid item xs={12} lg={9}>
              <StatusPicture
                data={picture}
                error={pictureError}
              />
            </Grid>
          </Grid>
        </Container>
      </OperationBox>
      <UsageBox>
        <Container maxWidth="lg">
          <Grid container item
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={1}
            xs={12}
            style={{ minHeight: getHeight() }}
          >
          </Grid>
        </Container>
      </UsageBox>
    </div>
  )
}
