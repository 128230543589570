import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { isNil } from 'lodash';
import solar from './parts/Solar.svg';
import battery from './parts/Battery.svg';
import house from './parts/House.svg';
import grid from './parts/Grid.svg';
import Line from './parts/Line';
import MultiStepLine from './parts/MultiStepLine';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '16px 16px 0',
    width: '100%',
    minHeight: '425px',
    border: '1px solid #003f56',
    borderRadius: '4px',
    [theme.breakpoints.down("md")]: {
      padding: '10px',
    }
  },
  vrSeparator: {
    maxWidth: '9%',
    flexBasis: '9%',
    height: '160px',
    [theme.breakpoints.down("xs")]: {
      height: '50px',
      maxWidth: '100%',
      flexBasis: '100%',
    }
  },
  hrSeparator: {
    maxWidth: '100%',
    flexBasis: '100%',
    height: '50px'
  },
  smCard: {
    maxWidth: '36%',
    flexBasis: '36%'
  },
  lgCard: {
    maxWidth: '54%',
    flexBasis: '54%'
  },
  cardContainer: {
    border: '1px solid #42596b',
    borderRadius: '4px',
    display: 'flex',
    height: '160px',
    position: 'relative',
    [theme.breakpoints.down("xs")]: {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  cardInfo: {
    backgroundColor: '#42596b',
    minWidth: '110px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down("md")]: {
      minWidth: '90px',
    }
  },
  cardInfoLabel: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '17px',
    fontFamily: 'HelveticaNeue-Light, sans-serif',
    letterSpacing: '1.1px'
  },
  cardInfoImg: {
    marginBottom: '24px'
  },
  cardDetailSection: {
    padding: '16px 24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down("md")]: {
      padding: '12px',
    }
  },
  lgCardDetails2: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cardLoadsSection: {

  },
  cardLabel: {
    fontSize: '14.8px',
    fontFamily: 'HelveticaNeue-Light, sans-serif',
    letterSpacing: 'normal',
  },
  cardLoadsTop: {
    height: '30px'
  },
  cardLoadsBottom: {
    marginTop: '15px',
    [theme.breakpoints.down("md")]: {
      marginTop: '0',
    }
  },
  cardLoadsKw: {
    marginTop: '13px',
    marginLeft: '4px',
    position: 'absolute',
    top: 0,
    [theme.breakpoints.down("xs")]: {
      margin: 0
    }
  },
  cardLoadsCenter: {
    fontSize: '46px',
    fontFamily: 'HelveticaNeue-Light, sans-serif',
    letterSpacing: '1.1px',
    position: 'relative'
  },
  batteryIndicator: {
    backgroundColor: '#42596b',
    width: '90px',
    borderRadius: '4px',
    position: 'relative',
    [theme.breakpoints.down("md")]: {
      width: '80px'
    }
  },
  batteryLevelLabel: {
    color: 'white',
    fontSize: '17px',
    fontFamily: 'HelveticaNeue-Light, sans-serif',
    paddingTop: '10px',
    textAlign: 'center',
    position: 'relative',
    zIndex: '10'
  },
  batteryLevelIndicator: {
    width: '100%',
    position: 'absolute',
    bottom: 0
  },
  batteryReserved: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    borderTop: '1.9px dashed #42596b'
  },
  buySellContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column'
  },
  buySell: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2px',
    color: 'rgba(255,255,255,.6)',
  },
  buySellLabel: {
    fontSize: '18px',
    fontFamily: 'HelveticaNeue-Light, sans-serif',
    marginRight: '10px',
    width: '32px',
    [theme.breakpoints.down("md")]: {
      marginRight: '5px',
      width: '30px',
    }
  },
  buySellValue: {
    width: '90px',
    fontSize: '28px',
    fontFamily: 'HelveticaNeue-Light, sans-serif',
    backgroundColor: '#42596b',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("md")]: {
      width: '80px'
    }
  },
  price: {
    fontSize: '12px',
    textAlign: 'right',
    marginBottom: '5px',
    marginRight: '20px'
  },
  xLineContainer: {
    position: 'absolute',
    right: '-67px'
  },
  yLineContainer: {
    position: 'absolute',
    left: '50%',
    top: '-70px'
  }
}));

const getCardImage = type => {
  switch (type) {
    case 'solar': return solar;
    case 'battery': return battery;
    case 'house': return house;
    case 'grid': return grid;
    default: return solar;
  }
}

const getColor = status => {
  if (status === "err") {
    return "red";
  } else if (status === "warn") {
    return "#f9b528";
  } else if (status === "act") {
    return "green";
  } else {
    return "#42596b";
  }
}

const getBatteryColor = (level) => {
  let color = 'red';
  if (level < 15) {
    color = 'red';
  } else if (level < 25) {
    color = '#f9b528';
  } else {
    color = '#4fc1ea'
  }
  return color;
}

const BatteryIndicator = ({ level = 0, reserved }) => {
  const classes = useStyles();
  return <div className={classes.batteryIndicator}>
    <div className={classes.batteryLevelLabel}>{`${level}%`}</div>
    <div className={classes.batteryLevelIndicator} style={{ backgroundColor: getBatteryColor(level), height: `${level}%` }} />
    {reserved && <div className={classes.batteryReserved} style={{ height: `${reserved}%` }} />}
  </div>
}

const Card = ({ size = 'sm', type = 'solar', data }) => {
  const classes = useStyles();
  if (isNil(data)) return null;
  const details = data[type];
  const arrows = data?.arrows || [];

  return <div className={`${classes.cardContainer} ${size === 'sm' ? classes.smCard : classes.lgCard}`}>
    <div className={classes.cardInfo} style={{ backgroundColor: getColor(details?.status) }}>
      <img className={classes.cardInfoImg} src={getCardImage(type)} alt="" />
      <div className={classes.cardInfoLabel}>{type}</div>
    </div>
    <div className={`${classes.cardDetailSection}`}>
      <div className={classes.cardLoadsSection} style={{ width: `${size === 'lg' && (type === 'battery' ? '60%' : '40%') }` }}>
        <div className={`${classes.cardLoadsTop} ${classes.cardLabel}`}>
          {type === 'battery' && details.mode}
          {type === 'grid' && details.mode === 'buy' ? 'importing' : details.mode === 'sell' ? 'exporting' : ''}
        </div>
        <div className={classes.cardLoadsCenter}>
          <span>{Number.parseFloat(details?.load/1000).toFixed(1)}</span>
          <span className={`${classes.cardLoadsKw} ${classes.cardLabel}`}>kW</span>
        </div>
        <div className={`${classes.cardLoadsBottom} ${classes.cardLabel}`}>
          {type === 'battery' && ((details?.reserved && details?.reserved !== 0) ? `${details.reserved}% backup reserved` : 'backup disabled') }
        </div>
      </div>
      {size === 'lg' && <div className={classes.lgCardDetails2} style={{ width: `${size === 'lg' && (type === 'battery' ? '40%' : '60%')}` }}>
        {type === 'battery' && <BatteryIndicator level={details?.level} reserved={details?.reserved} />}
      </div>
      }
    </div>
    {['solar', 'house'].includes(type) && <Line type={getHrArrowType(arrows, type)} />}
    {type === 'house' && <Line type={getHouseArrowType(arrows)} />}
    {type === 'house' && <MultiStepLine type={getMultilineType(arrows)} />}
  </div>
}

const getMultilineType = arrows => {
  const arrow = arrows.find(it => it.id === 2);
  if (arrow.mode === '') return null;
  return arrow.mode === 'L' ? 'left' : 'right';
}

const getHrArrowType = (arrows, type) => {
  const arrow = arrows.find(it => it.id === (type === 'solar' ? 3 : 4));
  if (arrow.mode === '') return null;
  return arrow.mode === 'L' ? 'left' : 'right';
}

const getHouseArrowType = arrows => {
  const arrow = arrows.find(it => it.id === 1);
  if (arrow.mode === '') return null;
  return arrow.mode === 'L' ? 'bottom' : 'top';
}

const Separator = ({ type = 'vr' }) => {
  const classes = useStyles();
  return <div className={type === 'vr' ? classes.vrSeparator : classes.hrSeparator} />
}

const StatusPicture = ({ data }) => {
  const classes = useStyles();
  
  return <Grid item container xs={12} className={classes.root}>
  <Card type="solar" data={data} />
  <Separator />
  <Card size="lg" type="battery" data={data} />
  <Separator type="hr" />
  <Card type="house" data={data} />
  <Separator />
  <Card size="lg" type="grid" data={data} />
</Grid>
}

export default StatusPicture;
