import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Visibility, VisibilityOff, Check } from '@material-ui/icons';
import { RefreshButton, StatusBox, ItemBox, Title, Value } from './parts'
import { ConfigPaper, ConfigBox, Header } from './parts';
import { sendNetworkSetting } from '../../../actions/networkActions'


const signalComparator = (a, b) => {
  if (a.signal > b.signal) return -1;
  if (a.signal < b.signal) return 1;
  return 0;
}

const SelectButton = styled(Button)({
  marginTop: '3px',
  maxWidth: 'inherit',
  height: '24px',
  fontSize: '12px',
  padding: '0px',
  border: "1px solid #004057",
  '&:hover': {
    color: '#fff',
    background: '#004057',
  },
  '&:disabled': {
  },
});

const ValueTextField = styled(TextField)({
  paddingTop: '0px',
  marginBottom: '18px',
  position: 'releative',
  fontSize: '14px',
  height: '34px',
  width: '100%',
  '& input': {
    color: '004057',
    background: '#fff',
    borderRadius: '4',
    border: 'none',
    fontSize: '14px',
    padding: '10px 10px',
    lineHeight: '1.42857143',
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    background: '#fff',
    paddingRight: '10px',
  },
});

function ApItem({ item, selected, onSelect }) {

  function onClick(e) {
    e.preventDefault();
    onSelect(item.ssid);
  }

  if(!item.ssid) return null;

  return (
    <ItemBox>
      <Grid container justify="space-between" alignItems="center" spacing={0}>
        <Grid item xs={7}>
          <Value>{item.ssid} {item.ssid===selected?<Check style={{ color: "green" }}/>:null}</Value>
        </Grid>
        <Grid item xs={2}>
          <Value align="center">{item.channel}</Value>
        </Grid>
        <Grid item xs={2}>
          <Value align="center">{item.signal}</Value>
        </Grid>
        <Grid item xs={1}>
          <SelectButton disabled={item.ssid===selected} onClick={e => onClick(e)}>Select</SelectButton>
        </Grid>
      </Grid>
    </ItemBox>
  );
}

export function WifiApList ({ open, refresh, onComplete, onClose}) {
  const dispatch = useDispatch()
  const listData = useSelector(state => state.network.apList)
  const listLoading = useSelector(state => state.network.apListLoading)
  const listError = useSelector(state => state.network.apListError)
  const setting = useSelector(state => state.network.setting)
  const settingLoading = useSelector(state => state.network.loading)
  const settingError = useSelector(state => state.network.error)
  const [ssid, setSsid] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const settingData = setting?.wifi;

  useEffect(() => {  
    if (settingData) {
      setSsid("");
      setPassword("");
    }
  }, [dispatch, settingData]);

  function handleSave(e) {
    e.preventDefault();
    dispatch(sendNetworkSetting(
      { ssid: ssid, password: password, method: 'auto', primary: true }
    ));
  }

  function handleCancel(e) {
    e.preventDefault();
    setSsid("");
    setPassword("");
  }

  function handleClickShowPassword(e) {
    setShowPassword(!showPassword);
  };

  function handleMouseDownPassword(e) {
    e.preventDefault();
  };

  function renderList() {
    if (!listData) {
      return (
        <Title>No Access Points Found!</Title>
      );
    }

    return(
      <StatusBox>
        {listError?<Alert severity="error">{listError.error_msg}</Alert>:null}
        <Grid container justify="space-between" alignItems="center" spacing={1}>
          <Grid item xs={7}>
            <Title>SSID</Title>
          </Grid>
          <Grid item xs={2}>
            <Title align="center">Ch</Title>
          </Grid>
          <Grid item xs={2}>
            <Title align="center">Level</Title>
          </Grid>
          <Grid item xs={1}>
          </Grid>
        </Grid>
        {listData.sort(signalComparator).map((item, idx) =>
          <ApItem key={'ap'+idx}
            item={item}
            selected={settingData?.ssid}
            onSelect={data => setSsid(data)}
          />
        )}
      </StatusBox>
    );
  }

  return (
    <ConfigPaper>
      <Header>
        Access Points
        {listLoading?<CircularProgress size={16}/>:<RefreshButton onClick={refresh}/>}
      </Header>
      <ConfigBox>
        {renderList()}
      </ConfigBox>
      <Dialog onClose={handleCancel} open={ssid!==''} maxWidth="sm" fullWidth>
        <DialogTitle>
          Enter the password for "{ssid}"
        </DialogTitle>
        <DialogContent>
          <ValueTextField
            variant="outlined"
            fullWidth
            name="password"
            type={showPassword?'text':'password'}
            id="password"
            value={password}
            onChange={(e) => {setPassword(e.target.value)}}
            InputProps={
              {endAdornment: 
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >{showPassword?<Visibility/>:<VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
              }
            }
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={settingLoading} onClick={handleCancel}>Cancel</Button>
          <Button autoFocus disabled={settingLoading} onClick={handleSave}>Save</Button>
        </DialogActions>      
      </Dialog>
    </ConfigPaper>
  );
};
