import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';


export const FormBox = styled(Box)({
  width: '100%',
  maxWidth: '400px',
  padding: '0px',
  marginTop: '30px'
});

export const ConfigBox = styled(Box)({
  fontSize: '13px',
  width: '100%',
  // minHeight: '35px',
  padding: '3px 0px 3px 0px',
  borderBottom: '1px solid #d9d9d9',
  '& .react-ip-input': {
    backgroundColor: '#fff',
    padding: '0px 6px 0px 6px',
    fontSize: '13px',
    width: '150px'
  },
  '& .react-ip-input__item': {
    padding: '0px',
    color: '#004057',
    /*background-color: red;*/
  },
  '& .react-ip-input input': {
    padding: '0px',
    width: '30px',
    height: '30px',
    color: 'inherit'
  },
  '& .react-ip-input i': {
    color: 'inherit'
  }
});

export const StatusBox = styled(Box)({
  width: '100%',
  padding: '0px',
  marginTop: '30px'
});

export const ItemBox = styled(Box)({
  fontSize: '13px',
  width: '100%',
  // minHeight: '35px',
  padding: '3px 0px 3px 0px',
  borderBottom: '1px solid #d9d9d9',
});

export const Title = styled(Typography)({
  fontSize: '16px',
  fontWeight: '100%',
  textTransform: 'capitalize',
  letterSpacing: '0.8px',
});

export const Label = styled(Typography)({
  fontSize: '13px',
  fontWeight: '100%',
  textTransform: 'capitalize',
  letterSpacing: '0.8px',
  marginTop: '1px',
  minHeight: '20px',
});

export const Header = styled(Box)({
  display: 'inline-flex',
  fontSize: '24px',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  letterSpacing: '1.2px',
  marginBottom: '20px',
  alignItems: 'center'
});

export const Value = styled(Typography)({
  fontSize: '13px',
  fontWeight: '100%',
  textTransform: 'none',
  letterSpacing: '0.8px',
  marginTop: '1px',
  minHeight: '20px',
});
