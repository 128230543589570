// Import all actions
import * as actions from '../../actions/debug/stateActions'

export const initialState = {
  data: [],
  loading: false,
  error: false,
}

export function StateReducer(state = initialState, action) {
  switch (action.type) {

    case actions.GET_DEBUG_STATE_REQUEST:
      return { ...state, loading: true }
    case actions.GET_DEBUG_STATE_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: false }
    case actions.GET_DEBUG_STATE_FAILURE:
      return { ...state, loading: false, error: true }

    case actions.PUT_DEBUG_STATE_CLEAR_REQUEST:
      return { ...state, loading: true }
    case actions.PUT_DEBUG_STATE_CLEAR_SUCCESS:
      return { ...state, loading: false, error: false }
    case actions.PUT_DEBUG_STATE_CLEAR_FAILURE:
      return { ...state, loading: false }

    default:
      return state
  }
}
