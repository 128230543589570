import React, { useState, useEffect } from 'react';
import './ipInput.css';

const IpInput = ({
    value,
    name,
    onChange,
    isError
}) => {
    const [ip1, setIp1] = useState('');
    const [ip2, setIp2] = useState('');
    const [ip3, setIp3] = useState('');
    const [ip4, setIp4] = useState('');

    useEffect(() => {
        if (!value) return;
        const list = value.split('.');
        const length = list.length;
        if (length > 0) {
            setIp1(list[0]);
            
            if (length > 1) {
                setIp2(list[1]);

                if (length > 2) {
                    setIp3(list[2]);

                    if (length > 3) {
                        setIp4(list[3]);
                    }
                }
            }
        }
    }, [value]);

    const onChangeInput = e => {
        const { value } = e.target;
        if ((value && !(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(value))) || Number(value) > 255) {
            return;
        }
        const targetName = e.target.name;
        if (targetName === 'ip1') {
            setIp1(value);
            if (value.length === 3) {
                document.getElementById(`${name}_ip2`).focus();
            }
            onChange(`${value}.${ip2}.${ip3}.${ip4}`);
        } else if (targetName === 'ip2') {
            setIp2(value);
            if (value.length === 3) {
                document.getElementById(`${name}_ip3`).focus();
            }
            onChange(`${ip1}.${value}.${ip3}.${ip4}`);
        } else if (targetName === 'ip3') {
            setIp3(value);
            if (value.length === 3) {
                document.getElementById(`${name}_ip4`).focus();
            }
            onChange(`${ip1}.${ip2}.${value}.${ip4}`);
        } else if (targetName === 'ip4') {
            setIp4(value);
            onChange(`${ip1}.${ip2}.${ip3}.${value}`);
        }
    }

    const onKeyUp = (e) => {
        if (!e.target.value && e.keyCode === 8) {
            const targetName = e.target.name;
            if (targetName === 'ip2') {
                document.getElementById(`${name}_ip1`).focus();
            } else if (targetName === 'ip3') {
                document.getElementById(`${name}_ip2`).focus();
            } else if (targetName === 'ip4') {
                document.getElementById(`${name}_ip3`).focus();
            }
        }
    }

    return <div className={`ipInput ${isError && 'ip-error'}`}>
        <input id={`${name}_ip1`} className="ipInput-field" name="ip1" maxLength={3} value={ip1} onChange={onChangeInput} onKeyUp={onKeyUp} />
        <span>.</span>
        <input id={`${name}_ip2`} className="ipInput-field" name="ip2" maxLength={3} value={ip2} onChange={onChangeInput} onKeyUp={onKeyUp} />
        <span>.</span>
        <input id={`${name}_ip3`} className="ipInput-field" name="ip3" maxLength={3} value={ip3} onChange={onChangeInput} onKeyUp={onKeyUp} />
        <span>.</span>
        <input id={`${name}_ip4`} className="ipInput-field" name="ip4" maxLength={3} value={ip4} onChange={onChangeInput} onKeyUp={onKeyUp} />
    </div>
}

export default IpInput;
