import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Grid, CircularProgress, Button } from '@material-ui/core';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import { ConfigPaper, ConfigBox, ConfigSelect, FormBox, Header, Label } from './parts';
import { RefreshButton } from './parts'
import { ValidateIPaddress } from '../../../utils/validate';
import { sendNetworkSetting } from '../../../actions/networkActions'

import _ from 'lodash';
import IpInput from '../../../components/IpInput/IpInput';

const ValueTextField = styled(TextField)({
  paddingTop: '2px',
  fontSize: '13px',
  height: '30px',
  width: '100%',
  '& input': {
    color: '004057',
    background: '#fff',
    borderRadius: '4',
    border: 'none',
    fontSize: '13px',
    padding: '6px 6px',
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    background: '#fff',
    paddingRight: '6px',
  },
});

const SaveButton = styled(Button)({
  marginTop: '5px',
  maxWidth: 'inherit',
  height: '30px',
  fontSize: '13px',
  padding: '0px',
  border: "1px solid #004057",
  '&:hover': {
    color: '#fff',
    background: '#004057',
  },
  '&:disabled': {
  },
});

const dhcpModes = [{ value: 'auto', label: 'DHCP' }, { value: 'manual', label: 'Static' },]

const connectionTypes = [{ value: 'wifi', label: 'WiFi' }, { value: 'ethernet', label: 'Ethernet' },]

const IpSetting = ({ title, name, values, handleChange }) => {
  if (values.method === 'auto') {
    return null;
  }

  return (
    <ConfigBox>
      <Grid container justify="space-between" alignItems="center" spacing={0}>
        <Grid item xs={12} sm={6}>
          <Label>{title}</Label>
        </Grid>
        <Grid item xs={12} sm={6}>
          <IpInput 
            isError={ValidateIPaddress(values[name])}
            name={name}
            value={values[name]}
            onChange={(value) => handleChange(name, value)}
          />
        </Grid>
      </Grid>
    </ConfigBox>
  );
}

export function WifiSetting ({ refresh }) {
  const dispatch = useDispatch();
  const setting = useSelector(state => state.network.setting);
  const loading = useSelector(state => state.network.loading);

  const [type, setType] = useState({});
  const [values, setValues] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const data = Object.values(setting).filter(item => item.primary)[0];

  // const data = setting?.ethernet

  React.useEffect(() => {
    setValues(data);
    setType(data?.hasOwnProperty("ssid")?'wifi':'ethernet');
  }, [data, setting]);

  function touched() {
    return !_.isEqual(data, values);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (values.method === 'auto' && type === 'wifi') {
      dispatch(sendNetworkSetting(
        {primary: true, method: 'auto', ssid: values.ssid, password: values.password}
      ));
    } else if (values.method === 'auto') {
      dispatch(sendNetworkSetting({primary: true, method: 'auto'}));
    } else {
      dispatch(sendNetworkSetting(values));
    }
  }

  function handleClickShowPassword(e) {
    setShowPassword(!showPassword);
  };

  function handleMouseDownPassword(e) {
    e.preventDefault();
  };

  function handleMethodSelect(e) {
    e.preventDefault();
    setValues({
      ...values,
      method: e.target.value,
    });
  }

  function handleTypeSelect(e) {
    e.preventDefault();
    setValues({
      ...values,
      ssid: "",
    });
    setType(e.target.value);
  }

  function handleChange(key, value) {
    setValues({
      ...values,
      [key]: value,
    });
  };

  function renderSave() {
    if (!touched()) {
      return null;
    }
    return (
      <SaveButton
        fullWidth
        type="submit"
        className="submit"
      >Save
      </SaveButton> 
    );
  }

  function ModeSelect() {
    return (
      <ConfigBox>
        <Grid container justify="space-between" alignItems="center" spacing={0}>
          <Grid item xs={12} sm={6}>
            <Label>IP Mode</Label>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ConfigSelect
              label="IP Mode"
              name="mode"
              value={values.method}
              onChange={(e) => handleMethodSelect(e)}
              options={dhcpModes}
              disabled={false}
            />
          </Grid>
        </Grid>
      </ConfigBox>
    );
  }

  function TypeSelect() {
    return (
      <ConfigBox>
        <Grid container justify="space-between" alignItems="center" spacing={0}>
          <Grid item xs={12} sm={6}>
            <Label>Connection</Label>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ConfigSelect
              label="IP Mode"
              name="type"
              value={type}
              onChange={(e) => handleTypeSelect(e)}
              options={connectionTypes}
              disabled={false}
            />
          </Grid>
        </Grid>
      </ConfigBox>
    );
  }

  if (!values || !data) {
    return null;
  }

  console.log('values', values);

  return (
    <ConfigPaper>
      <Header>
        Settings
        {loading?<CircularProgress size={16}/>:<RefreshButton onClick={refresh}/>}
      </Header>
      <FormBox>
        <form onSubmit={handleSubmit} className="network-form">
          <TypeSelect />
          <Grid container spacing={0} justify="center" alignItems="center">
            {type == 'wifi' && <ConfigBox>
              <Grid container justify="space-between" alignItems="center" spacing={0}>
                <Grid item xs={12} sm={6}>
                  <Label>SSID</Label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueTextField
                    id="ssid"
                    size="small" 
                    variant="outlined"
                    value={values.ssid}
                    onChange={(e) => handleChange('ssid', e.target.value)}
                  />
                </Grid>
              </Grid>
            </ConfigBox>}
            {type == 'wifi' && <ConfigBox>
              <Grid container justify="space-between" alignItems="center" spacing={0}>
                <Grid item xs={12} sm={6}>
                  <Label>Password</Label>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ValueTextField
                    id="password"
                    size="small" 
                    variant="outlined"
                    type={showPassword?'text':'password'}
                    value={values.password}
                    onChange={(e) => handleChange('password', e.target.value)}
                    InputProps={
                      {endAdornment: 
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >{showPassword?<Visibility/>:<VisibilityOff/>}
                          </IconButton>
                        </InputAdornment>
                      }
                    }
                  />
                </Grid>
              </Grid>
            </ConfigBox>
            }
            <ModeSelect />
            <IpSetting title="IP Address" name="ip" values={values} handleChange={handleChange} />
            <IpSetting title="Netmask" name="netmask" values={values} handleChange={handleChange} />
            <IpSetting title="Default Gateway" name="gateway" values={values} handleChange={handleChange} />
            <IpSetting title="Primary DNS" name="dns1" values={values} handleChange={handleChange} />
            <IpSetting title="Secondary DNS" name="dns2" values={values} handleChange={handleChange} />
            {renderSave()}
          </Grid>
        </form>
      </FormBox>
    </ConfigPaper>
  );
};
