import API from '../api';

// Create Redux action types
export const GET_EVENT_DISPLAY_REQUEST = 'GET_EVENT_DISPLAY_REQUEST';
export const GET_EVENT_DISPLAY_SUCCESS = 'GET_EVENT_DISPLAY_SUCCESS';
export const GET_EVENT_DISPLAY_FAILURE = 'GET_EVENT_DISPLAY_FAILURE';

// Create Redux action creators that return an action
export const getEventDisplayRequest = () => ({
  type: GET_EVENT_DISPLAY_REQUEST,
})

export const getEventDisplaySuccess = (data) => ({
  type: GET_EVENT_DISPLAY_SUCCESS,
  payload: data,
})

export const getEventDisplayFailure = () => ({
  type: GET_EVENT_DISPLAY_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchEvents() {
  return async (dispatch) => {
    dispatch(getEventDisplayRequest());
    try {
      const response = await API.get('/api/display/');
      dispatch(getEventDisplaySuccess(response.data));
    } catch (error) {
      dispatch(getEventDisplayFailure());
    }
  }
}

export const SET_DO_LOGOUT = 'SET_DO_LOGOUT';

export function setDoLogout(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_DO_LOGOUT,
      payload: value
    })
  }
}
