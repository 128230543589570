import { combineReducers } from 'redux';

import { StatusReducer } from './statusReducer'
import { BmsReducer } from './bmsReducer'
import { SignalsReducer } from './signalsReducer'
import { StateReducer } from './stateReducer'

export default combineReducers({
  status: StatusReducer,
  bms: BmsReducer,
  signals: SignalsReducer,
  state: StateReducer,
});
