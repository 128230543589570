import React from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { OperationTitle } from '../Typography/Title'
import OperationSelect from '../Select/OperationSelect';
import _ from 'lodash';

import './operation.css';

const StatusBox = styled(Box)({
  maxWidth: '800px',
});

const UpdateButton = styled(Button)({
  background: '#003f56',
  color: 'inherit',
  height: '40px',
  width: "100%",
  borderRadius: '3px',
  border: 'solid 1px #102d39',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  letterSpacing: '1px',
  '&:hover': {
    background: '#102d39',
  },
});

const ClearButton = styled(Button)({
  background: '#003f56',
  color: 'inherit',
  height: '40px',
  width: "100%",
  borderRadius: '3px',
  border: 'solid 1px #102d39',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  letterSpacing: '1px',
  '&:hover': {
    background: '#CC0000',
    border: 'solid 1px #CC0000',
  },
});

const StatusLabel = styled(Typography)({
  fontSize: '13px',
  letterSpacing: '0.8px',
});

const StatusValue = styled(Typography)({
  fontSize: '13px',
  letterSpacing: '0.8px',
  paddingLeft: '10px',
});

const StatusSubValue = styled(Typography)({
  fontSize: '11px',
  fontStyle: 'italic',
  letterSpacing: '0.8px',
  paddingLeft: '10px',
});

class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      localState: this.props.data
    };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  } 
  
  touched() {
    return !_.isEqual(this.state.localState, this.props.data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      if (!this.touched() || this.localState === undefined) {
        this.setState({ localState: nextProps.data });
      } else {
        this.setState({ showAlert: true });
      }
    }
  }

  handleUpdate(e) {
    e.preventDefault();
    this.props.update(this.state.localState);
    // this.setState({ localState: this.props.data });
  }

  handleSelectChange(e) {
    this.setState(prevState => (
      { localState: { ...prevState.localState, [e.target.name]: e.target.value } }
    ));
  }

  renderButton() {
    const {
      loggedIn,
      updating,
      clearHalt
    } = this.props;
    const {
      localState
    } = this.state;

    if (loggedIn  && !updating) {
      if (this.touched() && localState.halted) {
        return (
          <React.Fragment>
            <Grid item xs={6}>
              <UpdateButton variant="outlined" onClick={e => this.handleUpdate(e)}>Apply</UpdateButton>
            </Grid>
            <Grid item xs={6}>
              <ClearButton variant="outlined" onClick={clearHalt}>Clear Halt</ClearButton>
            </Grid>
          </React.Fragment>
        )
      } else if (this.touched()) {
        return (
          <Grid item xs={12}>
            <UpdateButton variant="outlined" onClick={e => this.handleUpdate(e)}>Apply Changes</UpdateButton>
          </Grid>
        )
      } else if (localState.halted) {
        return (
          <Grid item xs={12}>
            <ClearButton variant="outlined" onClick={clearHalt}>Clear Halt</ClearButton>
          </Grid>
        )
      }
    }
  }

  render() {
    const {
      loggedIn,
      loading,
      updating
    } = this.props;
    const {
      localState
    } = this.state;

    if (localState === undefined) {
      return (
        <CircularProgress size={20} />
      );
    }    

    console.log(localState.operation);

    return (
      <Grid container justify="center">  
        <StatusBox>
          <Grid container item
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
            xs={12}
          >
            <Grid item xs={12}>
              <OperationTitle component="div" className="titleSection">
                System Status                
                {(loading||updating)? <CircularProgress size={20} />:null}                
              </OperationTitle>
            </Grid>
            <OperationSelect
              label="Grid"
              name="interactive"
              value={localState.interactive}
              onChange={this.handleSelectChange}
              options={[{ value: 'on', label: 'Connected' }, { value: 'off', label: 'Disconnected' }]}
              disabled={updating||!loggedIn}
            />
            <Grid item xs={4}>
              <StatusLabel>Operation</StatusLabel>
            </Grid>
            <Grid item xs={8}>
              <StatusValue>{localState.operation?.label}</StatusValue>
              <StatusSubValue>{localState.operation?.command}</StatusSubValue>
            </Grid>
            {this.renderButton()}         
          </Grid>
        </StatusBox>
      </Grid>
    );
  }
}

export default Status;
