// Import all actions
import * as actions from '../../actions/installation/gridActions'

export const initialState = {
  region: undefined,
  regionOption: [],
  regionSetting: [],
  regionLoading: false,
  regionError: false,
  setting: [],
  settingLoading: false,
  settingError: false,
}

export function GridReducer(state = initialState, action) {
  switch (action.type) {

    case actions.GET_GRID_REGION_REQUEST:
    case actions.PUT_GRID_REGION_REQUEST:
    case actions.GET_GRID_REGION_OPTION_REQUEST:
      return { ...state, regionLoading: true }
    case actions.GET_GRID_REGION_SUCCESS:
    case actions.PUT_GRID_REGION_SUCCESS:
      return {
        ...state,
        region: action.payload.region,
        regionSetting: action.payload.settings,
        regionLoading: false,
        regionError: false
      }
    case actions.GET_GRID_REGION_OPTION_SUCCESS:
      return {
        ...state,
        regionOption: action.payload,
        regionLoading: false,
        regionError: false
      }  
    case actions.GET_GRID_REGION_FAILURE:
    case actions.PUT_GRID_REGION_FAILURE:
    case actions.GET_GRID_REGION_OPTION_FAILURE:
      return { ...state, regionLoading: false, regionError: action.payload }
    case actions.PUT_GRID_REGION_CLEAR_ERROR:
      return { ...state, regionError: false }

    case actions.GET_GRID_SETTING_REQUEST:
    case actions.PUT_GRID_SETTING_REQUEST:
      return { ...state, settingLoading: true }
    case actions.GET_GRID_SETTING_SUCCESS:
    case actions.PUT_GRID_SETTING_SUCCESS:
      let new_setting = [ ...state.setting ];
      let obj = new_setting.find(x => x.parameter === action.payload.parameter);
      if (obj) {
        Object.assign(obj, action.payload);
      } else {
        new_setting.push(action.payload);
      }
      return {
        ...state,
        setting: new_setting,
        settingLoading: false,
        settingError: false
      }
    case actions.GET_GRID_SETTING_FAILURE:
    case actions.PUT_GRID_SETTING_FAILURE:
      return { ...state, settingLoading: false, settingError: action.payload }
    case actions.PUT_GRID_SETTING_CLEAR_ERROR:
      return { ...state, settingError: false }

    default:
      return state
  }
}
