import { withStyles } from '@material-ui/core/styles';
import { Cached } from '@material-ui/icons';


export const RefreshIcon = withStyles({
  root: {
    color: 'inherit',
    fontSize: '24px',
  },
})(Cached);
