import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { DebugPaper } from '../Paper/Paper';
import { RefreshButton } from '../Button/IconButton';
import { CommandButton } from '../Button/Button';
import { DebugTable, StatusTableCell } from '../Table/DebugTable';

const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

// ===========================================================================

class StatusGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      data,
      group
    } = this.props;

    const keys = Object.keys(data);

    return (
      <DebugPaper>
        <TableContainer component={DebugPaper}>
          <DebugTable>
            <TableHead>
              <TableRow>
                <StatusTableCell>
                  {group}
                </StatusTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keys.map((key, id) =>
                <TableRow key={'value' + group + id}>
                  <StatusTableCell component="th" scope="row">
                    {titleCase(key)}
                  </StatusTableCell>
                  <StatusTableCell align="right">
                    {isNaN(data[key])?data[key]:Math.round(data[key]*100)/100}
                  </StatusTableCell>
                </TableRow>
              )}
            </TableBody>
          </DebugTable>
        </TableContainer>
      </DebugPaper>
    )
  }
}

// ===========================================================================

class StatusDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      loading,
      data,
      clearHalt,
      clearing,
      refresh,
      error
    } = this.props;

    const groups = Object.keys(data);

    if (error) {
      return (
        <DebugPaper> 
          No data available
        </DebugPaper>
      );  
    }

    return (
      <DebugPaper>
        <Grid container item
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
          xs={12}>
          <Grid item>
            <Grid container item
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={2}>
              <Grid item>
                Status Summary
              </Grid>
              <Grid item>
                {loading ? 
                   <CircularProgress size={16}/>
                  :<RefreshButton onClick={refresh}/>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container item
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={2}>
              <Grid item>
                <CommandButton disabled={true}>Download</CommandButton>
              </Grid>
              <Grid item>
                <CommandButton
                  onClick={clearHalt}
                  disabled={clearing}
                >Clear Halt
                </CommandButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}
          xs>
          {groups.sort().map(key =>
            <Grid item key={key} xl={2} lg={3} md={4} sm={6} xs={12}>
              <StatusGroup key={key+'group'}
                data={data[key]}
                group={key}/>
            </Grid>
          )}
        </Grid>
      </DebugPaper>
    );
  }
}

export default StatusDisplay;
