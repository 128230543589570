import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';


export const ValueTextField = styled(TextField)({
  paddingTop: '2px',
  fontSize: '13px',
  height: '30px',
  width: '100%',
  '& input': {
    color: '#004057',
    background: '#fff',
    borderRadius: '4',
    border: 'none',
    fontSize: '13px',
    padding: '6px 6px',
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    background: '#fff',
    paddingRight: '6px',
  },
});

export function PasswordField(props) {
  const [show, setShow] = useState(false);

  return(
    <ValueTextField
      id="password"
      size="small"
      variant="outlined"
      type={show?'text':'password'}
      value={props.value?props.value:""}
      onChange={props.onChange}
      InputProps={
        {endAdornment: 
          <InputAdornment position="end">
            <IconButton
              size="small"
              aria-label="toggle password visibility"
              onClick={() => setShow(!show)}
              onMouseDown={(e) => e.preventDefault()}
            >{show?<Visibility/>:<VisibilityOff/>}
            </IconButton>
          </InputAdornment>
        }
      }
      {...props}
    />
  );
}
