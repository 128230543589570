import React from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { OperationTitle } from '../Typography/Title'
import DebugOperationSelect from '../Select/DebugOperationSelect';
import { operationOptions } from '../../utils/enums'
import _ from 'lodash';

const StatusBox = styled(Box)({
  maxWidth: '800px',
});

const UpdateButton = styled(Button)({
  background: '#42596b',
  color: 'white',
  height: '40px',
  width: "100%",
  borderRadius: '3px',
  border: 'solid 1px #003f56',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  letterSpacing: '1px',
  '&:hover': {
    background: '#003f56',
  },
});

const ClearButton = styled(Button)({
  background: '#42596b',
  color: 'white',
  height: '40px',
  width: "100%",
  borderRadius: '3px',
  border: 'solid 1px #003f56',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  letterSpacing: '1px',
  '&:hover': {
    background: '#CC0000',
    border: 'solid 1px #CC0000',
  },
});

const StatusLabel = styled(Typography)({
  fontSize: '13px',
  letterSpacing: '0.8px',
  marginBottom: '20px',
});

const StatusValue = styled(Typography)({
  fontSize: '13px',
  letterSpacing: '0.8px',
  paddingLeft: '10px',
  
});

const StatusSubValue = styled(Typography)({
  fontSize: '11px',
  fontStyle: 'italic',
  letterSpacing: '0.8px',
  paddingLeft: '10px',
  marginBottom: '20px',
});

class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localState: {battery: 'off', limit: '1000', duration: '3600'}
    };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  } 
  
  handleUpdate(e) {
    e.preventDefault();
    this.props.update(this.state.localState);
  }

  handleSelectChange(e) {
    this.setState(prevState => (
      { localState: { ...prevState.localState, [e.target.name]: e.target.value } }
    ));
  }

  render() {
    const {
      loggedIn,
      loading,
      operation,
      clearHalt
    } = this.props;
    const {
      localState
    } = this.state; 

    return (
      <Grid container justify="center">  
        <StatusBox>
          <Grid container item
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
            xs={12}
          >
            <Grid item xs={12}>
              <OperationTitle component="div" className="titleSection">
                System Operation                
                {loading? <CircularProgress size={20} />:null}                
              </OperationTitle>
            </Grid>           
            <Grid item xs={4}>
              <StatusLabel>Operation</StatusLabel>
            </Grid>
            <Grid item xs={8}>
              <StatusValue>{operation?.operation?.label}</StatusValue>
              <StatusSubValue>{operation?.operation?.command}</StatusSubValue>
            </Grid>
            <DebugOperationSelect
              label="Battery"
              name="battery"
              value={localState.battery}
              onChange={this.handleSelectChange}
              options={operationOptions.BATTERY_OPTIONS}
              disabled={!loggedIn}
            />
            <DebugOperationSelect
              label="Limit"
              name="limit"
              value={localState.limit}
              onChange={this.handleSelectChange}
              options={operationOptions.BATTERY_LIMIT_OPTIONS}
              disabled={!loggedIn}
            />
            <DebugOperationSelect
              label="Duration"
              name="duration"
              value={localState.duration}
              onChange={this.handleSelectChange}
              options={operationOptions.DURATION_OPTIONS}
              disabled={!loggedIn}
            />
            <Grid item xs={12}>
              <UpdateButton
                variant="outlined"
                disabled={!loggedIn}
                onClick={e => this.handleUpdate(e)}>
                  Create Operation
              </UpdateButton>
            </Grid>
            {operation?.halted?<Grid item xs={12}>
              <ClearButton
                variant="outlined"
                onClick={clearHalt}
                disabled={!loggedIn}>
                  Clear Halt
              </ClearButton>
            </Grid>:null}   
          </Grid>
        </StatusBox>
      </Grid>
    );
  }
}

export default Status;
