// Import all actions
import * as actions from '../../actions/debug/signalsActions'

const series_colors = ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
                       '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'];
// ============================================================================
export const initialState = {
  options: [],
  graphs: [{id: 0, series: [], values: []}],
  id: 1,
  clockDivider: 1000,
  adcClock: 15000000,
  stopOnFault: false,
  loading: false,
  error: false,
}

export function SignalsReducer(state = initialState, action) {
  let data = null;
  let graph = null;
  let tmpGraphs = [];

  switch (action.type) {
    
    case actions.GET_SIGNALS_LOAD_REQUEST:
    case actions.GET_SIGNALS_OPTIONS_REQUEST:
    case actions.GET_SIGNALS_CLOCKDIVIDER_REQUEST:
    case actions.PUT_SIGNALS_CLOCKDIVIDER_REQUEST:
    case actions.GET_SIGNALS_STOPONFAULT_REQUEST:
    case actions.PUT_SIGNALS_STOPONFAULT_REQUEST:      
    case actions.PUT_SIGNALS_CLEAR_REQUEST:
      return { ...state, loading: true }

    case actions.GET_SIGNALS_OPTIONS_FAILURE:
    case actions.GET_SIGNALS_CLOCKDIVIDER_FAILURE:
    case actions.PUT_SIGNALS_CLOCKDIVIDER_FAILURE:
    case actions.GET_SIGNALS_STOPONFAULT_FAILURE:
    case actions.PUT_SIGNALS_STOPONFAULT_FAILURE:
    case actions.PUT_SIGNALS_CLEAR_FAILURE:
    case actions.GET_SIGNALS_LOAD_FAILURE:
      return { ...state, loading: false, error: true }

    case actions.GET_SIGNALS_OPTIONS_SUCCESS:
      return { ...state, options: action.payload, loading: false, error: false }

    case actions.SIGNALS_ADD_GRAPH:
      return { ...state, graphs: state.graphs.concat({id: state.id, series: [], values: []}), id: state.id+1 }
    case actions.SIGNALS_REMOVE_GRAPH:
      return { ...state, graphs: state.graphs.filter(x => x.id !== action.payload)}
    case actions.SIGNALS_REMOVE_SERIES:
      graph = state.graphs.filter(x => x.id === action.payload.id)[0];
      /* Remove series from graph */
      graph.series = graph.series.filter(n => n.key !== action.payload.value.key);
      graph.values = graph.values.filter(n => n.key !== action.payload.value.key);
      /* Remove old graph from array */
      tmpGraphs = state.graphs.filter(n => n.id !== action.payload.id);
      /* Add new graph to array */
      return { ...state, graphs: tmpGraphs.concat(graph) }

    case actions.GET_SIGNALS_LOAD_SUCCESS:
      data = action.payload.data;
      graph = state.graphs.filter(x => x.id === action.payload.id)[0];
      data.name = data.display_name;
      data.color = null
      let tmp = graph.series.filter(x => x.key === action.payload.key.key);
      if (tmp.length) {
        data.color = tmp[0].color
      } else {
        data.color = series_colors[graph.series.length];
      }
      /* Remove olde entries */
      graph.series = graph.series.filter(x => x.key !== action.payload.key.key);
      graph.values = graph.values.filter(x => x.key !== action.payload.key.key);
      /* Add new entries */
      graph.series = graph.series.concat(data);
      graph.values = graph.values.concat(action.payload.key);
      graph.clk_div = graph.series[0].clk_div;
      /* Remove old graph from array */
      tmpGraphs = state.graphs.filter(n => n.id !== action.payload.id);
      return { ...state, graphs: tmpGraphs.concat(graph), loading: false, error: false }  

    case actions.GET_SIGNALS_CLOCKDIVIDER_SUCCESS:
    case actions.PUT_SIGNALS_CLOCKDIVIDER_SUCCESS:
      return { ...state, clockDivider: action.payload.div, adcClock: action.payload.clk, loading: false, error: false }

    case actions.GET_SIGNALS_STOPONFAULT_SUCCESS:
    case actions.PUT_SIGNALS_STOPONFAULT_SUCCESS:
      return { ...state, stopOnFault: action.payload.value, loading: false, error: false }

    case actions.PUT_SIGNALS_CLEAR_SUCCESS:
      return { ...state, loading: false, error: false }

    default:
      return state
  }
}

