import React from 'react';
import { Grid, Icon, CircularProgress } from '@material-ui/core';
import { TableContainer, TableRow, TableHead, TableBody } from '@material-ui/core';
import { DebugPaper } from '../Paper/Paper';
import { RefreshButton } from '../Button/IconButton';
import { Panel,  PanelSummary, PanelDetails } from '../Panel/ExpansionPanel';
import { StatusPanel,  StatusPanelSummary, StatusPanelDetails } from '../Panel/ExpansionPanel';
import { DebugTable, DebugTableCell } from '../Table/DebugTable';
import { RedDot, GreenDot } from '../Dot/Dot';


const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

const bits = {
  default: 16,
  shutdown: 8,
  system: 8,
  under_temperature: 4,
  status: 8,
  over_temperature: 4,
  input: 6,
  battery: 8,
  warning: 7,
  fault: 7,
  error: 7,
  pl455: 8,
  comms: 7
}

const names = {
  default: 'Cell 1,Cell 2,Cell 3,Cell 4,Cell 5,Cell 6,Cell 7,Cell 8,Cell 9,Cell 10,Cell 11,Cell 12,Cell 13,Cell 14,Cell 15,Cell 16',
  powerdown: "Analog Die Thermal Shutdown,VP Too Slow,Reserved,Reserved,Analog Die Shutdown Request,V5VAO Brown Out,Digital Thermal Shutdown or VIO Brown Out,Power Down",
  system: "VP Clamping,VP Fault,VM Fault,VDIG Fault,Internal Temperature Fault,VDIG Wake Fault,Comms Timeout,System Reset",
  under_temperature: "Sensor 1,Sensor 2,Sensor 3,Sensor 4",
  summary: "Reserved,Reserved,Reserved,Reserved,Reserved,Reserved,Input,Chip,System,Communications,Comparator over voltage,Comparator under voltage,Under temperature,Over temperature,Over voltage,Under voltage",
  status: "System Initialising,New Data Available,Automonitor Running,Comms Reset,Comms Clear,Stack Fault Latched,Stack Fault,Fault",
  over_temperature: "Sensor 1,Sensor 2,Sensor 3,Sensor 4",
  communication: "Stack Input Error,Reserved,Reserved,Stop Bit Error,Reserved,Frame Error,Low Side Crc Error,High Side Crc Error,Low Side Abort,High Side Abort,Low Side Edge Error,High Side Edge Error,Low Side Frame Error,High Side Frame Error,Low Side Complement Error,High Side Complement Error",
  device: "Factory ECC Error,Factory ECC Fault Corrected,User ECC Error,User ECC Fault Corrected,ADC Test Failure,Reserved,Reserved,Reserved,Reserved,Reserved,Reserved,HREF GND Fault,HREF Fault,Analog Die Fault,Factory Checksum Error,User Checksum Error",
  inputs: "Input 1,Input 2,Input 3,Input 4,Input 5,Input 6",
  battery: "Balancing Active,Balancing Allowed,Data Ready,Data Valid,Battery Empty,Battery Full,Limiting Charge,Charge Termination",
  warning: "Cell Temperature Difference,Cell Voltage Difference,Cell Temperature Low,Cell Temperature High,Cell Voltage Low,Cell Voltage High,Current High",
  fault: "Any Warning,Any Error,Current,Masked,Override,Fuel Gauge,PL455",
  error: "Cell Temperature Difference,Cell Voltage Difference,Cell Temperature Low,Cell Temperature High,Cell Voltage Low,Cell Voltage High,Current High",
  pl455: "Data Initialisation,Info Initialisation,Communication Initialisation,Communication Reset,Communication Failure,Clear Failure,Timeout,Power",
  comms: "Receive Timeout,Transmit Timeout,Buffer Overflow,Parity Error,Overrun Error,Framing Error,Timeout"
}

// ========================================

class StatusArray extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };    
    this.handleOpen = this.handleOpen.bind(this);
    this.renderDots = this.renderDots.bind(this);
    this.renderTable = this.renderTable.bind(this);
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  renderDots() {
    const {
      id,
      value
    } = this.props;

    var name_arr = names[id]?names[id].split(','):names['default'].split(',');
    var data = [];
    var num_bits = bits[id]?bits[id]:bits['default']
    for (var i=num_bits-1;i>=0;i--) {
      var item = {
        name: name_arr[i],
        index: i,
        color: ((value>>i)&1)?'red':'green',
      }
      data.push(item);
    }

    return (
      <div>
        {data.map((item, id) =>  
          <Icon fontSize="inherit" component="span" key={id}>
             {item.color==='red'?<RedDot />:<GreenDot />}
          </Icon>
        )}
      </div>
    );
  }

  renderTable() {
    const {
      id,
      value,
      loading
    } = this.props;

    var name_arr = names[id]?names[id].split(','):names['default'].split(',');
    var data = [];
    var num_bits = bits[id]?bits[id]:bits['default']
    for (var i=num_bits-1;i>=0;i--) {
      var item = {
        name: name_arr[i],
        index: i,
        color: ((value>>i)&1)?'red':'green'
      }
      data.push(item);
    }
  
    return (     
      <TableContainer component={DebugPaper}>
        <DebugTable>
          <colgroup>
              <col width="20px" />
              <col />
              <col width="55px" />
          </colgroup>
          <TableHead>
            <TableRow>
              <DebugTableCell/>
              <DebugTableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, idx) => (
              <TableRow key={"statusArray"+idx}>
                <DebugTableCell  component="th" scope="row">
                  <Icon fontSize="inherit" component="span" key={idx}>
                    {item.color==='red'?<RedDot />:<GreenDot />}
                  </Icon>
                </DebugTableCell>
                <DebugTableCell >
                  {item.name}
                </DebugTableCell>
              </TableRow>
            ))}
          </TableBody>
        </DebugTable>
      </TableContainer>
    );
  }

  render() {
    const {
      title
    } = this.props;
    const {
      open
    } = this.state;

    return (
      <StatusPanel expanded={open} onChange={this.handleOpen}>
        <StatusPanelSummary
          aria-controls={title + 'content'}
          id={title}
        >
          <Grid container item xs={12}>
            <Grid item xs={12}>
              {titleCase(title)}
            </Grid>
            <Grid item xs={12}>
              {open ? null:this.renderDots()}
            </Grid>
          </Grid>
        </StatusPanelSummary>
        <StatusPanelDetails>
          <div>
            {open ? this.renderTable():null}
          </div>
        </StatusPanelDetails>
      </StatusPanel>
    );
  }
}

// ========================================

function StatusArrayGroup ({ group, data, loading }) {

  if (!data) return null;

  const keys = Object.keys(data).sort();

  return (
    <Panel defaultExpanded={true}>
      <PanelSummary
        aria-controls={group + 'content'}
        id={group}
      >{group} 
      </PanelSummary>
      <PanelDetails>
        <div>
          {keys.map(key =>
            <StatusArray 
              key={key}
              id={key}
              title={key}
              value={data[key]}
              loading={loading}
            />
          )}
        </div>
      </PanelDetails>
    </Panel>
  );
}

// ========================================

export default function StatusArrayList ({ data, loading, refresh, update }) {
  return (
    <DebugPaper>
      <Grid container item
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={1}
        xs={12}>
        <Grid item>
          Status
        </Grid>
        <Grid item>
          {loading ? <CircularProgress size={16} />:<RefreshButton onClick={refresh}/>}
        </Grid>
      </Grid>

      <StatusArrayGroup 
        key='system'
        group='system'
        data={data.system}
        loading={loading}
      />

      <StatusArrayGroup 
        key='chip0'
        group='chip0'
        data={data.chip0}
        loading={loading}
      />
      <StatusArrayGroup 
        key='chip1'
        group='chip1'
        data={data.chip1}
        loading={loading}
      />
      <StatusArrayGroup 
        key='chip2'
        group='chip2'
        data={data.chip2}
        loading={loading}
      />
    </DebugPaper>
  );
}
