import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';

export const DebugTable = withStyles({
  root: {
    tableLayout: 'fixed',
    width: '100%',
  },
})(Table);

export const DebugTableCell = withStyles({
  head: {
    color: 'inherit',
    fontSize: '11px',
    fontWeight: 'normal',
    lineHeight: '12px',
    letterSpacing: 'normal',
    textTransform: 'capitalize',
    padding: '3px',
  },  
  body: {
    color: 'inherit',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '13px',
    letterSpacing: 'normal',
    textTransform: 'none',
    padding: '3px',
  },
})(TableCell);

export const StatusTableCell = withStyles({
  head: {
    color: 'inherit',
    fontSize: '18px',
    fontWeight: '150%',
    letterSpacing: '1.0px',
    textTransform: 'capitalize',
    padding: '3px',
  },  
  body: {
    color: 'inherit',
    fontSize: '13px',
    fontWeight: 'normal',
    lineHeight: '14px',
    letterSpacing: 'normal',
    textTransform: 'none',
    padding: '3px',
  },
})(TableCell);

