import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container';
import { fetchDebugState } from '../../actions/debug/stateActions'
import State from '../../components/Debug/State';

// ========================================

export function DebugStatePage () {
  const dispatch = useDispatch()
  const data = useSelector(state => state.debug.state)

  useEffect(() => {
    dispatch(fetchDebugState());
  }, [dispatch]);
 
  return (
    <Container maxWidth="xl">
      <State
        data={data.data}
        columns={data.options}
        refresh={() => dispatch(fetchDebugState())}
      /> 
    </Container>
  );

}

