import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import { Box, Grid, Button } from '@material-ui/core';
import { fetchEvents } from '../../actions/eventActions'

const Page = styled(Box)({
  height: '100vh',
});

const Header = styled(Box)({
  background: '#42596b',
  color: '#fff'
});

const Logo = styled(Box)({
  float: 'left',
  margin: '20px 0',
  height: '54px',
});

const SplashBox = styled(Box)({
  height: 'calc(100% - 100px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  fontSize: '32px',
  color: '#42596b'
});

const Retry = styled(Button)({
  width: '100px',
  background: '#42596b',
  color: '#fff',
  marginTop: '10px',
  '&:hover': {    
    color: '#42596b',
    border: '1px solid #42596b'
  }
});

const SplashScreen = () => {
  const [timer, setTimer] = useState(30);
  const dispatch = useDispatch();

  const loading = useSelector(state => state.event.loading);

  useEffect(() => {
    const intervalOp = setInterval(() => {
      if (timer === 1) {
        onClickRetry();
      } else {
        setTimer(timer - 1);
      }
    }, 1000);
    return () => clearInterval(intervalOp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const onClickRetry = () => {
    setTimer(30);
    dispatch(fetchEvents());
  }

  return (
    <Page>
        <Header>
            <Grid container direction="column" justify="center" alignItems="center">
              <Grid item>
                <Logo component="img" src="/static/images/logo.png"></Logo>
              </Grid>
            </Grid>
        </Header>
        <SplashBox>
          <div>Re-connecting in {timer} seconds.</div>
          <Retry onClick={onClickRetry} disabled={loading}>Retry</Retry>
        </SplashBox>
    </Page>
  );
}

export default SplashScreen;
